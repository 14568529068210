export default {
  'ResetPasswordScreen.keynotfound': {
    en: 'Your reset key is invalid or expired.',
    fr: 'Votre clé est invalide ou expirée.',
  },
  'ResetPasswordScreen.success': {
    en: 'Your password has been reset.',
    fr: 'Votre mot de passe a été réinitialisé.',
  },
  'ResetPasswordScreen.password': {
    en: 'New password',
    fr: 'Nouveau mot de passe',
  },
  'ResetPasswordScreen.confirmpassword': {
    en: 'Confirm new password',
    fr: 'Confirmez votre mot de passe',
  },
  'ResetPasswordScreen.updatePassword': {
    en: 'Update Password',
    fr: 'Mettre à jour',
  },
};
