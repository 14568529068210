import * as React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';

import HomeScreen from './home/HomeScreen';
import NotFoundScreen from './404/NotFoundScreen';
import AuthScreen from './auth/AuthScreen';
import ExploreScreen from './explore/ExploreScreen';
import ProfileScreen from './profile/ProfileScreen';
import EmailVerificationScreen from './emailVerification/EmailVerificationScreen';
import TrainerDetailsScreen from './trainerDetails/TrainerDetailsScreen';
import BookingScreen from './booking/BookingScreen';
import ResetPasswordScreen from './resetPassword/ResetPasswordScreen';

const routes = [
  <Switch key="switch">
    <Route exact path="/" component={HomeScreen} key="HomeScreen" />,
    <Route exact path="/auth" component={AuthScreen} key="AuthScreen" />,
    <Route
      exact
      path="/explore"
      component={ExploreScreen}
      key="ExploreScreen"
    />
    ,
    <Route
      exact
      path="/verify"
      component={EmailVerificationScreen}
      key="EmailVerificationScreen"
    />
    ,
    <Route
      exact
      path="/resetPassword"
      component={ResetPasswordScreen}
      key="ResetPasswordScreen"
    />
    ,
    <Route
      exact
      path="/profile"
      component={ProfileScreen}
      key="ProfileScreen"
    />
    ,
    <Route
      path="/trainer/:id"
      component={TrainerDetailsScreen}
      key="TrainerDetailsScreen"
    />
    ,
    <Route exact path="/book" component={BookingScreen} key="BookingScreen" />
    <Route path="*" component={NotFoundScreen} key="Notfound404" />
  </Switch>,
];

export default routes;
