import * as React from 'react';
import colors from '../../constants/colors';
import styled from 'styled-components';
import { TextSubtext, TextNormal500 } from '../common/Typography';
import { margins } from '../../constants/metrics';

function InfoBlock({
  icon,
  title,
  label,
  labelStyle = {},
}: {
  icon: string;
  title: string;
  label: string;
  labelStyle?: React.CSSProperties;
}) {
  return (
    <Main>
      <Image src={icon} />
      <TextSubtext
        style={{
          marginTop: margins[1],
          color: colors.lightGray,
          marginBottom: 2,
        }}
      >
        {title}
      </TextSubtext>
      <TextNormal500 style={{ color: colors.black, ...labelStyle }}>
        {label}
      </TextNormal500>
    </Main>
  );
}

export default InfoBlock;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => props.theme.paddingsCSS[3]};
`;

const Image = styled.img`
  width: 41px;
  height: 41px;
`;
