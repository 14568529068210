import * as React from 'react';
import styled from 'styled-components';
import { TextSubtitle, TextNormal } from '../common/Typography';
import colors from '../../constants/colors';
import { margins, marginsCSS } from '../../constants/metrics';

function ExplanationBox({
  src,
  title,
  body,
}: {
  src: string;
  title: string;
  body: string;
}) {
  return (
    <Main>
      <TopImage src={src} />
      <TextSubtitle
        style={{
          color: colors.black,
          marginBottom: margins[3],
          fontWeight: 600,
        }}
      >
        {title}
      </TextSubtitle>
      <TextNormal style={{ color: colors.lightGray }}>{body}</TextNormal>
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 305px;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: ${(props) => props.theme.marginsCSS[10]};
    width: 100%;
    align-items: center;
    span {
      text-align: center;
    }
  }
`;

const TopImage = styled.img`
  margin-bottom: ${marginsCSS[5]};
`;

export default ExplanationBox;
