import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useUserStore } from '../../stores/user';
import { useRouterStore } from '../../stores/router';
import { useUiStore } from '../../stores/ui';
import TransactionCell from './TransactionCell';
import FittLoading from '../common/FittLoading';
import Button from '../common/Button';
import i18n from '../../constants/i18n';
import FittSelect from '../common/FittSelect';
import metrics from '../../constants/metrics';
import { formatError } from '../../utils/text';
import { Id, TransactionDocument } from '../../types';
import { TextSubtitle } from '../common/Typography';
import colors from '../../constants/colors';
import images from '../../constants/images';

export type FilterValues = 'upcoming' | 'past' | 'all';

export type FilterItem = { value: FilterValues; label: string };

const ProfileScreen = observer(() => {
  const userStore = useUserStore();
  const router = useRouterStore();

  const filterItems: FilterItem[] = [
    { value: 'upcoming', label: i18n('ProfileScreen.upcoming', 'Upcoming') },
    { value: 'past', label: i18n('ProfileScreen.past', 'Past') },
    { value: 'all', label: i18n('ProfileScreen.all', 'All') },
  ];

  const [isFetchingInit, setIsFetchingInit] = React.useState(true);
  const [selectedFilter, setSelectedFilter] = React.useState<FilterItem>(
    filterItems[0],
  );

  React.useEffect(() => {
    const firstFetch = async () => {
      setIsFetchingInit(true);
      await Promise.all([
        userStore.fetchUpcomingSessions(),
        userStore.fetchPastSessions(),
      ]);
      setIsFetchingInit(false);
    };
    firstFetch();
  }, []);

  const upcomingTransactions = userStore.getUpcomingSessions();
  const pastTransactions = userStore.getPastSessions();

  const emptyUpcoming =
    selectedFilter.value === 'upcoming' && upcomingTransactions.length === 0;
  const emptyPast =
    selectedFilter.value === 'past' && pastTransactions.length === 0;
  const emptyAll = emptyUpcoming && emptyPast;

  const renderEmptyState = emptyUpcoming || emptyPast || emptyAll;

  const uiStore = useUiStore();

  const _onPressCancel = ({
    transaction,
  }: {
    transaction: TransactionDocument;
  }) => {
    const { status, id, service } = transaction;

    const feesWarning = status === 'accepted';

    if (service.serviceType !== '1session') {
      uiStore!.openAlert({
        title: i18n('WorkoutCell.warning', 'Warning'),
        text: `All of the sessions associated with this package will also be cancelled.${
          feesWarning
            ? `Note that payment processing fees won't be refunded.`
            : ''
        } Are you sure you want to continue?`,

        buttons: [
          {
            label: i18n('WorkoutCell.yes', 'Yes'),
            onClick: () => {
              uiStore!.closeAlert();
              _cancel({ transactionId: id });
            },
          },
          {
            label: i18n('WorkoutCell.no', 'No'),
            onClick: uiStore!.closeAlert,
          },
        ],
      });
    } else {
      if (feesWarning) {
        uiStore!.openAlert({
          title: i18n('WorkoutCell.warning', 'Please Confirm'),
          text: i18n(
            `WorkoutCell.fullTxt`,
            `Any single session may be cancelled and fully refunded up to 24 hours prior to the booking date & time. Cancellations with less than 24 hours notice cannot be refunded. Are you sure you want to continue?`,
          ),

          buttons: [
            {
              label: i18n('WorkoutCell.yes', 'Yes'),
              onClick: () => {
                uiStore!.closeAlert();
                _cancel({ transactionId: id });
              },
            },
            {
              label: i18n('WorkoutCell.no', 'No'),
              onClick: uiStore!.closeAlert,
            },
          ],
        });
      } else {
        uiStore!.openAlert({
          title: i18n('WorkoutCell.warning', 'Warning'),
          text: i18n(
            'WorkoutCell.cancelText',
            `Any single session may be cancelled and fully refunded up to 24 hours prior to the booking date & time. Cancellations with less than 24 hours notice cannot be refunded. Are you sure you want to continue?`,
          ),

          buttons: [
            {
              label: i18n('WorkoutCell.yes', 'Yes'),
              onClick: () => {
                uiStore!.closeAlert();
                _cancel({ transactionId: id });
              },
            },
            {
              label: i18n('WorkoutCell.no', 'No'),
              onClick: uiStore!.closeAlert,
            },
          ],
        });
      }
    }
  };

  const _cancel = async ({ transactionId }: { transactionId: Id }) => {
    try {
      setIsFetchingInit(true);
      await userStore.updateSessionState({
        action: 'cancel',
        transactionId: transactionId,
      });
    } catch (e) {
      uiStore!.openAlert({
        title: 'Error',
        text: formatError(e),
      });
    } finally {
      setIsFetchingInit(false);
    }
  };

  if (
    isFetchingInit ||
    userStore.isFetchingUpcomingSessions ||
    userStore.isFetchingPastSessions
  ) {
    return (
      <Main>
        <FittLoading
          style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}
        />
      </Main>
    );
  }

  return (
    <Main>
      <RightColumn>
        <FittSelect
          value={selectedFilter.label}
          items={filterItems}
          onChange={(item) => setSelectedFilter(item)}
          style={{ width: 225, paddingBottom: metrics.safeTopDistance }}
        />

        {selectedFilter.value === 'past'
          ? null
          : upcomingTransactions.map((transaction) => (
              <TransactionCell
                key={transaction.id}
                transaction={transaction}
                onPressCancel={(transaction: TransactionDocument) =>
                  _onPressCancel({ transaction: transaction })
                }
              />
            ))}
        {selectedFilter.value === 'upcoming'
          ? null
          : pastTransactions.map((transaction) => (
              <TransactionCell
                key={transaction.id}
                transaction={transaction}
                onPressCancel={(transaction: TransactionDocument) =>
                  _onPressCancel({ transaction: transaction })
                }
              />
            ))}

        {renderEmptyState ? (
          <TextAndImg>
            <NoTransactionImg src={images.icons.trainerAndClient} />
            <TextSubtitle
              style={{
                color: colors.gray,
                fontSize: 28,
                textAlign: 'center',
              }}
            >
              You don't have any sessions in this category.
            </TextSubtitle>
          </TextAndImg>
        ) : null}
      </RightColumn>
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex: 1;
  padding-top: ${(props) => props.theme.safeTopDistance}px;
  padding-bottom: ${(props) => props.theme.safeTopDistance}px;

  @media (max-width: ${(props) => props.theme.breakPoints.small}px) {
    flex-direction: column;
  }

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.theme.marginsCSS[4]};
  order: 2;
  @media (max-width: ${(props) => props.theme.breakPoints.small}px) {
    margin-bottom: ${(props) => props.theme.safeTopDistance}px;
    order: 1;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: ${(props) => props.theme.marginsCSS[0]};
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.theme.marginsCSS[4]};
  order: 1;
  @media (max-width: ${(props) => props.theme.breakPoints.small}px) {
    margin-bottom: ${(props) => props.theme.safeTopDistance}px;
    order: 2;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: ${(props) => props.theme.marginsCSS[0]};
  }
`;

const TextAndImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoTransactionImg = styled.img`
  max-width: 800px;
  width: 100%;
  object-fit: contain;
`;

export default ProfileScreen;
