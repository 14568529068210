import * as React from 'react';
import { Box } from 'react-native-kondo';
import colors from '../../constants/colors';
import { TextNormal } from '../common/Typography';

const NotFoundScreen = () => (
  <Box flex={1} alignItems="center" justifyContent="center" bg={colors.white}>
    <TextNormal style={{ color: colors.black }}>404</TextNormal>
  </Box>
);

export default NotFoundScreen;
