import * as React from 'react';
import styled from 'styled-components';
import images from '../../constants/images';
import { TextNormal500, TextSubtitle } from '../common/Typography';
import i18n from '../../constants/i18n';
import { GoThreeBars } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { breakPoints, margins } from '../../constants/metrics';
import { useRouterStore } from '../../stores/router';
import { observer } from 'mobx-react';
import { useUserStore } from '../../stores/user';
import { useUiStore } from '../../stores/ui';
import { TouchableOpacity } from 'react-native';
import { getDeviceLangage, setDeviceLanguage } from '../../constants/i18n';

import colors from '../../constants/colors';
import Button from '../common/Button';
import WelcomePopup from '../explore/WelcomePopup';

export default observer(() => {
  const router = useRouterStore();
  const userStore = useUserStore();
  const uiStore = useUiStore();
  const isFrench = getDeviceLangage() === 'fr';
  if (
    router.location.pathname.includes('verify') ||
    router.location.pathname.includes('resetPassword')
  ) {
    return null;
  }

  return (
    <Main>
      <Link to="/" className={'a'}>
        <Logo alt="logo" src={images.icons.fittcoachLogo} />
      </Link>

      <RightSide>
        <TouchableOpacity
          onPress={() => {
            uiStore.openAlert({
              renderContent: () => <WelcomePopup />,
            });
          }}
          style={{ marginRight: 20 }}
        >
          <TextNormal500>
            {i18n('ProfileScreen.promo', 'Promotions & Discounts')}
          </TextNormal500>
        </TouchableOpacity>

        <Button
          label={i18n('ProfileScreen.download', 'Download the app')}
          // secondary
          style={{ marginRight: 20, height: 30 }}
          onClick={() => {
            uiStore.openAlert({
              renderContent: () => <WelcomePopup />,
            });
          }}
        />
        <Link
          to="/explore"
          style={{ marginRight: margins[8], textDecoration: 'none' }}
          className={'a'}
        >
          <TextNormal500>
            {i18n('NavBar.browsefittcoach', 'Browse Fittcoaches')}
          </TextNormal500>
        </Link>

        <Languages>
          <TouchableOpacity
            onPress={() => setDeviceLanguage('fr')}
            style={{ marginRight: 5 }}
          >
            <TextNormal500
              style={{ color: isFrench ? colors.black : colors.gray }}
            >
              FR
            </TextNormal500>
          </TouchableOpacity>

          <TextNormal500 style={{ marginRight: 5 }}>/</TextNormal500>

          <TouchableOpacity
            onPress={() => setDeviceLanguage('en')}
            style={{ marginRight: 20 }}
          >
            <TextNormal500
              style={{ color: isFrench ? colors.gray : colors.black }}
            >
              EN
            </TextNormal500>
          </TouchableOpacity>
        </Languages>

        {userStore.token ? (
          <Link
            to="/profile"
            style={{ textDecoration: 'none' }}
            className={'a'}
          >
            <TextNormal500 style={{ color: colors.lightSecondary }}>
              {i18n('NavBar.mySessions', 'My Sessions')}
            </TextNormal500>
          </Link>
        ) : (
          <Link to="/auth" style={{ textDecoration: 'none' }} className={'a'}>
            <TextNormal500>{i18n('NavBar.signin', 'Sign in')}</TextNormal500>
          </Link>
        )}
        {userStore.token ? (
          <Button
            label={i18n('ProfileScreen.logOut', 'Log out')}
            secondary
            style={{ marginLeft: 20, height: 30, width: 120 }}
            onClick={() => {
              userStore.logOut();
              router.push('/');
            }}
          />
        ) : null}
      </RightSide>

      {uiStore.isMobile ? (
        <TouchableOpacity onPress={uiStore.openDrawer}>
          <GoThreeBars size={32} color={colors.primary} />
        </TouchableOpacity>
      ) : null}
    </Main>
  );
});

const Logo = styled.img`
  /* ... */
`;

const Main = styled.div`
  display: flex;
  height: ${(props) => props.theme.navBarHeight}px;
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  padding: 0px ${(props) => props.theme.paddings[6]}px;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.borders};
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 2;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakPoints.mobile}px) {
    display: none;
  }
`;

const Languages = styled.div`
  display: flex;
  align-items: center;
`;
