export const colors = {
  primary: '#F15D1E',
  secondary: '#02877a',
  lightSecondary: '#00a393',
  paleGreen: '#5AB56E',
  lightPrimary: '#FB996C',
  lightGray: '#959595',
  gray: '#747474',
  borders: '#EFEFEF',
  background: '#FDFDFD',
  white: '#FFFFFF',
  black: '#333333',
  error: '#f76565',
};

export default colors;
