import * as React from 'react';
import { ReviewDocument } from '../../types';
import images from '../../constants/images';
import colors from '../../constants/colors';
import styled from 'styled-components';
import { TextSubtitle, TextNormal600, TextNormal } from '../common/Typography';
import { margins } from '../../constants/metrics';

function ReviewCell({ review, css }: { review: ReviewDocument; css?: string }) {
  const { user, ratingOverall, text } = review;
  return (
    <Main css={css}>
      <NameAndRating>
        <TextSubtitle
          style={{
            color: colors.black,
            marginRight: margins[1],
            fontWeight: 600,
          }}
        >
          {user.firstName + ' ' + user.lastName}
        </TextSubtitle>
        <StarImg src={images.icons.star} alt={'star'} />
        <TextNormal600
          style={{
            color: colors.primary,
            fontSize: 18,
            marginTop: 3,
            marginLeft: 3,
          }}
        >
          {ratingOverall}
        </TextNormal600>
      </NameAndRating>
      <TextNormal>{text}</TextNormal>
    </Main>
  );
}

export default ReviewCell;

const Main = styled.div<{ css?: string }>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.paddingsCSS[6]};
  ${(props) => props.css}
`;

const NameAndRating = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${(props) => props.theme.paddingsCSS[1]};
`;

const StarImg = styled.img`
  height: 20.24px;
  width: 20.24px;
`;
