import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { observer } from 'mobx-react';
import { useTrainerStore } from '../../stores/trainer';
import TrainerCell from './TrainerCell';
import FittLoading from '../common/FittLoading';

import styled from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';
import metrics, { margins } from '../../constants/metrics';
import { useGymStore } from '../../stores/gym';
import FittSelector from '../common/FittSelector';
import i18n from '../../constants/i18n';
import { useUiStore } from '../../stores/ui';
import WelcomePopup from './WelcomePopup';

const ExploreScreen = observer(() => {
  const trainerStore = useTrainerStore();
  const gymStore = useGymStore();
  const [windowWidth] = useWindowSize();
  const [isFetchingInit, setIsFetchingInit] = React.useState(true);
  const uiStore = useUiStore();

  React.useEffect(() => {
    uiStore.openAlert({
      renderContent: () => <WelcomePopup />,
    });
    const firstFetch = async () => {
      setIsFetchingInit(true);
      await Promise.all([
        trainerStore.fetchTrainers({
          limit: 30,
        }),
        gymStore.fetchGyms(),
      ]);
      setIsFetchingInit(false);
    };
    firstFetch();
  }, []);

  const trainers = trainerStore.getTrainers();
  const gyms = gymStore.getGyms();
  const selectedGym = gymStore.getSelectedGym();

  return (
    <Main>
      {!isFetchingInit && (
        <FittSelector
          mainStyle={{ maxWidth: 600 }}
          placeholder={i18n('ExploreScreen.placeholder', 'Search gym..')}
          label={i18n(
            'ExploreScreen.selectorlabel',
            'Find a fittcoach at your gym',
          )}
          onChange={(selectedOption: any) => {
            gymStore.setSelectedGym(
              // @ts-ignore
              selectedOption ? selectedOption.value : undefined,
            );
            trainerStore.fetchTrainers();
          }}
          value={
            selectedGym
              ? {
                  value: selectedGym,
                  label: `${
                    selectedGym.company ? selectedGym.company.name + ' ' : ''
                  }${selectedGym.name} - ${selectedGym.location.address1}`,
                }
              : undefined
          }
          options={gyms.map((gym) => ({
            value: gym,
            label: `${gym.company ? gym.company.name + ' ' : ''}${gym.name} - ${
              gym.location.address1
            }`,
          }))}
        />
      )}

      {isFetchingInit || trainerStore.isFetchingTrainers ? (
        <FittLoading
          style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}
        />
      ) : null}
      {!trainerStore.isFetchingTrainers && (
        <InfiniteScroll
          style={{
            overflow: 'hidden',
            flexWrap:
              windowWidth < metrics.breakPoints.mobile ? 'nowrap' : 'wrap',
            justifyContent:
              windowWidth < metrics.breakPoints.mobile
                ? 'flex-start'
                : 'space-evenly',
            flexDirection:
              windowWidth < metrics.breakPoints.mobile ? 'column' : 'row',
            display: isFetchingInit ? 'none' : 'flex',
            flex: 1,
            paddingTop: margins[12],
          }}
          dataLength={trainers.length} //This is important field to render the next data
          next={async () => {
            // await trainerStore.fetchTrainers();
          }}
          hasMore={true}
          loader={<div style={{ width: 0, height: 0, position: 'absolute' }} />}

          // below props only if you need pull down functionality
          // refreshFunction={this.refresh}
          // pullDownToRefresh
          // pullDownToRefreshThreshold={50}
          // pullDownToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
          // }
          // releaseToRefreshContent={
          //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
          // }
        >
          {trainers.map((trainer) => (
            <TrainerCell key={trainer.id} trainer={trainer} />
          ))}
        </InfiniteScroll>
      )}
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.safeTopDistance}px;
  flex-direction: column;
`;

export default ExploreScreen;
