/* eslint-disable react/prop-types */
import * as React from 'react';
import { TextSubtitle, TextNormal } from './Typography';
import colors from '../../constants/colors';

import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { margins } from '../../constants/metrics';
import ToolTip from './ToolTip';
import images from '../../constants/images';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
// @ts-ignore
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <ToggleContainer
    // bootstrap stuff
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <TextNormal style={{ color: colors.primary, fontSize: 22 }}>
      {children}
    </TextNormal>
    {/* <DownArrow
      alt="down arrow"
      src={require('../../public/img/downArrow.svg')}
    /> */}
  </ToggleContainer>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(({ children, ...rest }, ref) => {
  return (
    <div
      {...rest}
      // @ts-ignore
      ref={ref}
      style={{
        maxHeight: 200,
        overflow: 'auto',
        borderWidth: 1,
        borderColor: colors.borders,
        borderStyle: 'solid',
        // @ts-ignore
        'box-shadow': '0px 1px 5px -1px rgba(0, 0, 0, 0.1)',
      }}
    >
      {children}
    </div>
  );
});

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

// @ts-ignore
const CustomDropDownItem = React.forwardRef(({ children, onClick }, ref) => (
  <DropDownItemContainer
    // bootstrap stuff
    // @ts-ignore
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <TextNormal>{children}</TextNormal>
  </DropDownItemContainer>
));

interface Item {
  label: string;
  value: any;
}

const FittSelect = ({
  value,
  items,
  onChange,
  style = {},
  label,
  toolTip,
  disabled,
}: {
  value?: string;
  items?: Item[];
  onChange?: (item: Item, index: number) => void;
  style?: React.CSSProperties;
  label?: string;
  toolTip?: string;
  disabled?: boolean;
}) => {
  return (
    <Column style={{ ...style, opacity: disabled ? 0.3 : 1 }}>
      {label && (
        <div style={{ marginBottom: margins[1] }}>
          <TextSubtitle style={{ marginRight: margins[3] }}>
            {label}
          </TextSubtitle>
          {toolTip && <ToolTip style={{ marginTop: 5 }} text={toolTip} />}
        </div>
      )}
      {disabled ? (
        <ToggleContainer>
          <TextNormal style={{ color: colors.primary, fontSize: 22 }}>
            {value}
          </TextNormal>
          <DownArrow alt="down arrow" src={images.icons.downArrow} />
        </ToggleContainer>
      ) : (
        <Dropdown role="menu">
          <ToggleAndArrow>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              as={CustomToggle}
            >
              {value}
            </Dropdown.Toggle>
            <DownArrow alt="down arrow" src={images.icons.downArrow} />
          </ToggleAndArrow>

          <Dropdown.Menu as={CustomMenu}>
            {items &&
              items.map((item, index) => (
                <Dropdown.Item
                  key={item.label}
                  onSelect={onChange ? () => onChange(item, index) : undefined}
                  as={CustomDropDownItem}
                >
                  {item.label}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Column>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.black};
  padding: 0px ${(props) => props.theme.paddings[2]}px;
  cursor: pointer;

  height: 35px;
  font-size: 18px;

  min-width: 185px;
`;

const DropDownItemContainer = styled.div`
  display: flex;
  display: flex;
  align-items: center;
  height: 35px;
  min-width: 185px;
  padding: 0px ${(props) => props.theme.paddings[2]}px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colors.lightSecondary};
  }
  &:hover > span {
    color: white;
  }
  &:active {
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

const ToggleAndArrow = styled.div`
  display: flex;
  flex-direction: row;
`;

const DownArrow = styled.img`
  width: 24px;
  height: 14px;
  margin-top: ${(props) => props.theme.margins[2]}px;
`;

export default FittSelect;
