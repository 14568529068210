import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { observer } from 'mobx-react';
import { useUiStore } from '../../stores/ui';
import { Box } from 'react-native-kondo';
import { useUserStore } from '../../stores/user';
import { TouchableOpacity } from 'react-native';
import { Link } from 'react-router-dom';
import WelcomePopup from '../explore/WelcomePopup';
import { TextNormal500 } from '../common/Typography';
import i18n from '../../constants/i18n';
import Button from '../common/Button';
import { useRouterStore } from '../../stores/router';
import colors from '../../constants/colors';
import { margins } from '../../constants/metrics';

const MobileDrawer = observer(() => {
  const uiStore = useUiStore();
  const userStore = useUserStore();
  const router = useRouterStore();
  return (
    <Drawer
      anchor="right"
      open={uiStore.isDrawerOpen}
      onClose={uiStore.closeDrawer}
    >
      <Box flex={1} justifyContent="space-around" alignItems="flex-end" pr={15}>
        <TouchableOpacity
          onPress={() => {
            uiStore.openAlert({
              renderContent: () => <WelcomePopup />,
            });
          }}
        >
          <TextNormal500>
            {i18n('ProfileScreen.promo', 'Promotions & Discounts')}
          </TextNormal500>
        </TouchableOpacity>

        <Button
          label={i18n('ProfileScreen.download', 'Download the app')}
          // secondary
          style={{ height: 30 }}
          onClick={() => {
            uiStore.openAlert({
              renderContent: () => <WelcomePopup />,
            });
          }}
        />
        <Link to="/explore" style={{ textDecoration: 'none' }} className={'a'}>
          <TextNormal500>
            {i18n('NavBar.browsefittcoach', 'Browse Fittcoaches')}
          </TextNormal500>
        </Link>

        {userStore.token ? (
          <Link
            to="/profile"
            style={{ textDecoration: 'none' }}
            className={'a'}
          >
            <TextNormal500 style={{ color: colors.lightSecondary }}>
              {i18n('NavBar.mySessions', 'My Sessions')}
            </TextNormal500>
          </Link>
        ) : (
          <Link to="/auth" style={{ textDecoration: 'none' }} className={'a'}>
            <TextNormal500>{i18n('NavBar.signin', 'Sign in')}</TextNormal500>
          </Link>
        )}
        {userStore.token ? (
          <Button
            label={i18n('ProfileScreen.logOut', 'Log out')}
            secondary
            style={{ height: 30 }}
            onClick={() => {
              userStore.logOut();
              router.push('/');
            }}
          />
        ) : null}
      </Box>
    </Drawer>
  );
});

export default MobileDrawer;
