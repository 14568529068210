import * as React from 'react';
import styled from 'styled-components';
import FittBox from '../common/FittBox';
import images from '../../constants/images';
import { TextNormal500 } from '../common/Typography';
import i18n from '../../constants/i18n';
import { margins } from '../../constants/metrics';
import colors from '../../constants/colors';
import Button from '../common/Button';
import { useRouterStore } from '../../stores/router';
import { useUiStore } from '../../stores/ui';
import { formatError } from '../../utils/text';
import { validateEmail, requestEmailValidation } from '../../utils/api';
import { ClipLoader } from 'react-spinners';

const EmailVerificationScreen = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState('');
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [key, setKey] = React.useState('');
  const uiStore = useUiStore();

  React.useEffect(() => {
    const validate = async () => {
      try {
        setLoading(true);
        const urlString = window.location.href;
        const url = new URL(urlString);
        const key = url.searchParams.get('key');

        if (!key) {
          throw new Error(
            i18n(
              'EmailVerificationScreen.keynotfound',
              'Your email verification key is invalid.',
            ),
          );
        }
        setKey(key);
        await validateEmail({
          key,
        });
      } catch (e) {
        setError(formatError(e));
        uiStore.openToaster({
          type: 'error',
          text: formatError(e),
        });
      } finally {
        setLoading(false);
      }
    };
    validate();
  }, []);

  const router = useRouterStore();
  return (
    <Main>
      <FittBox
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: margins[10],
          paddingBottom: margins[10],
        }}
      >
        <ImageVerification src={images.verifycationImage} alt="image" />

        {loading && <ClipLoader size={32} color={colors.primary} />}

        {!error && !loading ? (
          <>
            <TextNormal500
              style={{
                marginBottom: margins[6],
                fontSize: 32,
                color: colors.secondary,
              }}
            >
              {i18n(
                'EmailVerificationScreen.titlesuccess',
                'Your email address is now verified!',
              )}
            </TextNormal500>
          </>
        ) : null}

        {!loading && error && key ? (
          <Button
            style={{ width: 400 }}
            label={i18n(
              'EmailVerificationScreen.Resend',
              'Resend email confirmation',
            )}
            loading={buttonLoading}
            onClick={async () => {
              try {
                setButtonLoading(true);
                await requestEmailValidation({
                  key,
                });
              } finally {
                setButtonLoading(false);
                uiStore.openToaster({
                  text: i18n('EmailVerificationScreen.sent', 'Email sent!'),
                });
              }
            }}
          />
        ) : null}
      </FittBox>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.paddings[6]}px;
`;

const ImageVerification = styled.img`
  margin-bottom: ${(props) => props.theme.margins[6]}px;
`;

export default EmailVerificationScreen;
