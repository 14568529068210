const numberOfDistances = 21;
const distances = Array.from({ length: numberOfDistances }, (_, i) => i * 5);

const distancesCSS = Array.from(
  { length: numberOfDistances },
  (_, i) => `${i * 5}px`,
);

export const breakPoints = {
  mobile: 768,
  small: 1000,
};

export const navBarHeightMobile = 50;
export const paddings = distances;
export const paddingsCSS = distancesCSS;
export const paddingHorizontal = 30;
export const margins = distances;
export const marginsCSS = distancesCSS;
export const borderRadius = [6, 8, 10];
export const boxShadow = 'box-shadow: 0px 60px 110px rgba(22, 52, 86, 0.04);';
export const maxContentWidth = 1280;
export const navBarHeight = 70;
export const safeTopDistance = 30;

export default {
  safeTopDistance,
  breakPoints,
  paddings,
  margins,
  navBarHeight,
  paddingHorizontal,
  borderRadius,
  maxContentWidth,
  paddingsCSS,
  marginsCSS,
};
