import * as React from 'react';
import useDidMount from '@rooks/use-did-mount';
import styled from 'styled-components';
import colors from '../../constants/colors';
import NavBar from '../navBar/NavBar';
import { maxContentWidth, paddings } from '../../constants/metrics';
import routes from '../routes';
import { useUserStore } from '../../stores/user';
import { observer } from 'mobx-react';
import { useRouterStore } from '../../stores/router';

import { withRouter } from 'react-router';
import AlertModal from '../common/AlertModal';
import Toaster from '../common/Toaster';
import HeroLanding from '../hero/HeroLanding';
import delay from '../../utils/delay';
import { useUiStore } from '../../stores/ui';
import MobileDrawer from '../navBar/MobileDrawer';

const MainLayout: React.FC = observer(() => {
  const uiStore = useUiStore();
  const userStore = useUserStore();
  const router = useRouterStore();
  const {
    location: { pathname },
  } = router;
  const hasToken = typeof localStorage.getItem('rft') === 'string';
  const [initialLoading, setInitialLoading] = React.useState(hasToken);

  const fetchMe = async () => {
    await delay(100);
    try {
      await userStore.fetchMe();
    } catch (e) {
      userStore.logOut();
    } finally {
      setInitialLoading(false);
    }
  };

  useDidMount(() => {
    uiStore.updateWindowSizes({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
    window.addEventListener('resize', () => {
      uiStore.updateWindowSizes({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      });
    });

    if (hasToken) {
      fetchMe();
    }
  });

  return (
    <>
      <MobileDrawer />
      <Main>
        <NavBar />
        {/* <HeroLanding /> */}

        {initialLoading ? null : (
          <Container>
            <Content>{routes}</Content>
          </Container>
        )}

        <AlertModal />
        <Toaster />
      </Main>
    </>
  );
});

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  min-height: 100vh;
  padding-top: ${(props) => props.theme.navBarHeight}px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    height: 100%;
    overflow-x: hidden;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: ${maxContentWidth}px;
  padding: 0px ${paddings[2]}px;
`;

export default withRouter(MainLayout);
