import * as React from 'react';
import { Box } from 'react-native-kondo';
import styled from 'styled-components';

const FittBox = ({
  children,
  style = {},
  css,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  css?: string;
}) => {
  return (
    <Main style={style} css={css}>
      {children}
    </Main>
  );
};

const Main = styled.div<{ css?: string }>`
  display: flex;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.borders};
  border-radius: 10px;
  padding: ${(props) => props.theme.paddings[4]}px;
  ${(props) => props.css}
`;

export default FittBox;
