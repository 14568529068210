import colors from './colors';
import metrics from './metrics';
import fonts from './fonts';

const theme = {
  ...metrics,
  backgroundGradientGray: `linear-gradient(180deg, #FFFFFF -12.89%, rgba(255, 255, 255, 0) 117.82%, #FFFFFF 117.82%)`,
  backgroundGradientPurple: `linear-gradient(138.9deg, #222340 26.57%, #2F3159 81.9%)`,
  colors,
  fonts,
};

export default theme;
