import BookingScreen from './BookingScreen'
import EmailVerificationScreen from './EmailVerificationScreen'
import ExploreScreen from './ExploreScreen'
import FittSelector from './FittSelector'
import HeroLanding from './HeroLanding'
import HomeScreen from './HomeScreen'
import LoginView from './LoginView'
import NavBar from './NavBar'
import ProfileScreen from './ProfileScreen'
import RecapScreen from './RecapScreen'
import ResetPasswordScreen from './ResetPasswordScreen'
import SocialButton from './SocialButton'
import SocialButtons from './SocialButtons'
import TrainerCell from './TrainerCell'
import TrainerDetailsScreen from './TrainerDetailsScreen'
import TransactionCell from './TransactionCell'
import WelcomePopup from './WelcomePopup'
import WorkoutCell from './WorkoutCell'
import goals from './goals'
const translations = {
...BookingScreen,
...EmailVerificationScreen,
...ExploreScreen,
...FittSelector,
...HeroLanding,
...HomeScreen,
...LoginView,
...NavBar,
...ProfileScreen,
...RecapScreen,
...ResetPasswordScreen,
...SocialButton,
...SocialButtons,
...TrainerCell,
...TrainerDetailsScreen,
...TransactionCell,
...WelcomePopup,
...WorkoutCell,
...goals,
};
export default translations;