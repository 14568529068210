import { observable, action, toJS } from 'mobx';
import { create, persist } from 'mobx-persist';

import {
  UserDocument,
  Id,
  TransactionDocument,
  UpdateSessionStatePayload,
  SessionInfo,
} from '../types/index';
import * as api from '../utils/api';
import uiStore from './ui';

import config from '../config';
import colors from '../constants/colors';
import { formatError } from '../utils/text';
import { useStores } from '.';
import { getDeviceLangage } from '../constants/i18n';

type KeyOfUser = Extract<keyof UserDocument, string>;

export interface LoginPayload {
  email?: string;
  password?: string;
  refreshToken?: string;
}

export interface EditMePayload {
  height?: string;
  weight?: string;
  language?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  notificationPreferences?: {
    emailMessages: boolean;
    pushMessages: boolean;
    emailReminders: boolean;
    pushReminders: boolean;
    emailPromotions: boolean;
    pushPromotions: boolean;
  };
  didCompleteOnboarding?: boolean;
  phone?: string;
  injury?: string;
  profilePicture?: string;
  workoutGoals?: string[];
  favorites?: Id[];
}

export interface EditMeAuthPayload {
  password: string;
  email?: string;
  phone?: string;
}

export interface TransactionPayload {
  trainerId: Id;
  sessions: BookingSession[];
  promoCode: string;
  message: string;
  stripeCardToken: string;
  serviceType:
    | '1session'
    | '3sessions'
    | '5sessions'
    | '10sessions'
    | 'workoutPlan';
  gymId: string;
  option: SessionInfo;
}

export interface BookingSession {
  skiped?: boolean;
  index: number;
  date: Date | undefined;
  from: any;
  to: any;
  nbOfGuests: number;
  nbOfHours: number;

  toHour: number;
  toMinutes: number;
  fromHour: number;
  fromMinutes: number;
  scheduleLater: boolean;
}

export interface UserStore {
  token: string | null;
  resetKey: string | null;
  setResetKey: (resetKey: string) => void;
  resetPassword: (payload: {
    resetKey: string;
    password: string;
    confirmPassword: string;
  }) => Promise<void>;
  requestPasswordReset: (payload: { email: string }) => Promise<void>;
  login: (payload: LoginPayload) => Promise<void>;
  loginWithGoogle: ({ accessToken }: { accessToken: string }) => Promise<void>;
  loginWithFacebook: ({
    accessToken,
  }: {
    accessToken: string;
  }) => Promise<void>;
  signup: (payload: { email: string; password: string }) => Promise<void>;
  me: UserDocument | null;
  getMe: (wantedKeys?: KeyOfUser[]) => UserDocument | null;
  // editMe: (
  //   payload: Partial<EditMePayload> | Partial<SignupPayload>,
  // ) => Promise<void>;
  editMeAuth: (payload: EditMeAuthPayload) => Promise<void>;
  fetchMe: () => Promise<void>;
  logOut: () => void;
  sendPushNotificationToken: () => Promise<void>;
  addOrRemoveFavorites: (payload: { trainerId: Id }) => Promise<void>;
  updateSessionState: (payload: UpdateSessionStatePayload) => Promise<void>;
  upcomingSessions: TransactionDocument[];
  pastSessions: TransactionDocument[];
  getUpcomingSessions: () => UserStore['upcomingSessions'];
  getPastSessions: () => UserStore['pastSessions'];
  fetchUpcomingSessions: () => Promise<void>;
  fetchPastSessions: (payload?: { limit?: number }) => Promise<void>;
  bookATrainer: (payload: TransactionPayload) => Promise<void>;

  isFetchingUpcomingSessions: boolean;
  isFetchingPastSessions: boolean;
}

class Us {
  @persist @observable token: UserStore['token'] = null;

  @observable resetKey: UserStore['resetKey'] = '';

  @observable me: UserStore['me'] = null;

  @observable upcomingSessions: UserStore['upcomingSessions'] = [];

  @observable pastSessions: UserStore['pastSessions'] = [];

  @observable isFetchingUpcomingSessions = false;

  @observable isFetchingPastSessions = false;

  @action
  setResetKey: UserStore['setResetKey'] = (resetKey) => {
    this.resetKey = resetKey;
  };

  @action
  shapeUser = async (
    userRepPayload: Partial<
      UserDocument & {
        accessToken: string;
        refreshToken: string;
        spyMode?: boolean;
      }
    >,
  ) => {
    const currentMe = this.getMe();
    if (!currentMe) {
      // @ts-ignore
      this.me = {};
    }

    const userRepPayloadKeys = Object.keys(userRepPayload);

    userRepPayloadKeys.forEach((key) => {
      if (key !== 'accessToken' && key !== 'refreshToken') {
        // @ts-ignore
        this.me[key] = userRepPayload[key];
      }
    });

    if (userRepPayload.accessToken && userRepPayload.refreshToken) {
      this.token = userRepPayload.accessToken;
      localStorage.setItem('rft', userRepPayload.refreshToken);
    }
  };

  @action
  login: UserStore['login'] = async (payload) => {
    const userRep = await api.login(payload);
    await this.shapeUser(userRep);
    await this._postAuthActions();
  };

  @action
  fetchMe: UserStore['fetchMe'] = async () => {
    const userRep = await api.getMe();
    await this.shapeUser(userRep);
    await this._postAuthActions();
  };

  getMe: UserStore['getMe'] = (wantedKeys?: KeyOfUser[]) => {
    if (this.me) {
      if (wantedKeys && wantedKeys.length) {
        const me: Partial<UserDocument> = {};
        wantedKeys.forEach((key) => {
          if (this.me![key]) {
            // @ts-ignore
            me[key] = toJS(this.me![key]);
          }
        });
        return me as UserDocument;
      } else {
        return toJS(this.me);
      }
    }
    return null;
  };

  // @action
  // editMe: UserStore['editMe'] = async (payload) => {
  //   const userRep = await api.editMe(payload);
  //   await this.shapeUser(userRep);
  // };

  @action
  editMeAuth: UserStore['editMeAuth'] = async (payload) => {
    const userRep = await api.editMeAuth(payload);
    await this.shapeUser(userRep);
  };

  @action
  addOrRemoveFavorites: UserStore['addOrRemoveFavorites'] = async (payload) => {
    const userRep = await api.addOrRemoveFavorites(payload);
    await this.shapeUser(userRep);
  };

  @action
  updateSessionState: UserStore['updateSessionState'] = async (payload) => {
    await api.updateSessionState(payload);
    await Promise.all([this.fetchUpcomingSessions(), this.fetchPastSessions()]);
  };

  @action
  _postAuthActions = async () => {
    // those are actions that must be perform
    // once a user sign up or login with any provider.
  };

  @action
  signup: UserStore['signup'] = async ({ email, password }) => {
    const userRep = await api.signup({
      email,
      password,
      language: getDeviceLangage(),
    });
    await this.shapeUser(userRep);
    await this._postAuthActions();
  };

  loginWithFacebook: UserStore['loginWithFacebook'] = async ({
    accessToken,
  }) => {
    try {
      const userRep = await api.loginWithFacebook({
        accessToken,
      });
      await this.shapeUser(userRep);
      await this._postAuthActions();
    } catch (e) {
      uiStore.openAlert({
        title: 'Error',
        text: formatError(e),
      });
    }
  };

  @action
  loginWithGoogle: UserStore['loginWithGoogle'] = async ({
    accessToken,
  }: {
    accessToken: string;
  }) => {
    try {
      const userRep = await api.loginWithGoogle({
        accessToken,
      });
      await this.shapeUser(userRep);
      await this._postAuthActions();
    } catch (e) {
      throw Error(e);
    }
  };

  @action
  requestPasswordReset: UserStore['requestPasswordReset'] = async (payload) => {
    await api.requestPasswordReset(payload);
  };

  @action
  resetPassword: UserStore['resetPassword'] = async (payload) => {
    await api.resetPassword(payload);
  };

  @action
  refreshAuth = async (
    userRepPayload: Partial<
      UserDocument & {
        accessToken: string;
        refreshToken: string;
        forceLogout?: boolean;
      }
    >,
  ) => {
    try {
      if (userRepPayload.forceLogout) {
        throw '';
      }

      await this.shapeUser(userRepPayload);
      await this._postAuthActions();
    } catch (e) {
      this.token = null;
      this.me = null;
      // await SecureStore.deleteItemAsync('rft');
    }
  };

  @action
  logOut = async () => {
    this.token = null;
    this.me = null;
    localStorage.removeItem('rft');
  };

  @action
  fetchUpcomingSessions: UserStore['fetchUpcomingSessions'] = async () => {
    try {
      this.isFetchingUpcomingSessions = true;
      const status: TransactionDocument['status'][] = ['accepted', 'pending'];
      const { transactions } = await api.getSessions({ status });
      this.upcomingSessions = transactions || [];
    } finally {
      this.isFetchingUpcomingSessions = false;
    }
  };

  @action
  fetchPastSessions: UserStore['fetchPastSessions'] = async (payload = {}) => {
    try {
      this.isFetchingPastSessions = false;
      const status: TransactionDocument['status'][] = [
        'cancelled',
        'completed',
        'rejected',
        'expired',
      ];
      const { transactions } = await api.getSessions({ status, ...payload });
      this.pastSessions = transactions || [];
    } finally {
      this.isFetchingPastSessions = false;
    }
  };

  @action
  bookATrainer: UserStore['bookATrainer'] = async (payload) => {
    await api.bookATrainer(payload);
    await this.fetchUpcomingSessions();
  };

  getUpcomingSessions = () => toJS(this.upcomingSessions);

  getPastSessions = () => toJS(this.pastSessions);
}

const hydrate = create({
  storage: localStorage,
});

const UserSaved = new Us();
hydrate('user', UserSaved).then(() => console.log('user has been hydrated'));
export default UserSaved;

export function useUserStore(): UserStore {
  const { user } = useStores();
  return user as UserStore;
}
