import { format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { cloneDeep } from 'lodash';
import { getDeviceLangage } from '../constants/i18n';
import { FrozenAvailability } from '../sharedTypes';

import { ScheduleDay, ScheduleDayTimeBlock } from '../types';

export interface TimeItem {
  value: string;
  timeblockIndex: number;
  time: Date;
  label: string;
}

export function generateTimeValues({
  scheduleDay,
  minTime,
  timeblockIndex,
  maxDurationInHour,
  frozenAvailabilities,
}: {
  scheduleDay: ScheduleDay;
  minTime?: string;
  timeblockIndex?: number;
  maxDurationInHour?: number;
  frozenAvailabilities?: FrozenAvailability[];
}) {
  let scheduleTimes: TimeItem[] = [];

  if (typeof timeblockIndex === 'undefined') {
    scheduleDay.timeblocks.forEach((timeblock, index) => {
      scheduleTimes = [
        ...scheduleTimes,
        ..._calculateByTimeblock({
          index,
          timeblock,
          minTime,
          maxDurationInHour,
        }),
      ];
    });
  } else {
    scheduleTimes = _calculateByTimeblock({
      index: timeblockIndex,
      timeblock: scheduleDay.timeblocks[timeblockIndex],
      minTime,
      maxDurationInHour,
    });
  }

  let sortedResult = scheduleTimes.sort(
    (a, b) => a.time.getTime() - b.time.getTime(),
  );

  if (frozenAvailabilities) {
    sortedResult = removeUnavailableValueBasedOnFrozenAvaibilities({
      timeItems: sortedResult,
      frozenAvailabilities,
      minTime,
    });
  }

  return sortedResult;
}

function _calculateByTimeblock({
  index,
  timeblock,
  minTime,
  maxDurationInHour = 1000000000,
}: {
  index: number;
  timeblock: ScheduleDayTimeBlock;
  minTime?: string;
  maxDurationInHour?: number;
}) {
  const scheduleTimes: TimeItem[] = [];
  let startDate = new Date(timeblock.from!);
  let possibleMinutes;

  if (minTime) {
    startDate = new Date(minTime);
    startDate.setHours(startDate.getHours() + 1);
    possibleMinutes = startDate.getMinutes();
  }

  let endDate = new Date(timeblock.to!);

  startDate = new Date(
    2020,
    1,
    1,
    startDate.getHours(),
    startDate.getMinutes(),
  );
  endDate = new Date(2020, 1, 1, endDate.getHours(), endDate.getMinutes());

  if (!minTime) {
    endDate.setHours(endDate.getHours() - 1);
  }

  const startHour = startDate.getHours();
  const startMinute = startDate.getMinutes();
  let minuteToAdd = 15;

  let currentTime = startDate;

  scheduleTimes.push({
    value: currentTime.toISOString(),
    time: new Date(currentTime.toISOString()),
    timeblockIndex: index,
    label: format(currentTime, 'p', {
      locale: getDeviceLangage().includes('fr') ? fr : enUS,
    }),
  });

  while (currentTime < endDate && scheduleTimes.length < maxDurationInHour) {
    currentTime = new Date(2020, 1, 1, startHour, startMinute + minuteToAdd);

    if (typeof possibleMinutes !== 'undefined') {
      if (possibleMinutes === currentTime.getMinutes()) {
        scheduleTimes.push({
          value: currentTime.toISOString(),
          time: new Date(currentTime.toISOString()),
          timeblockIndex: index,
          label: format(currentTime, 'p', {
            locale: getDeviceLangage().includes('fr') ? fr : enUS,
          }),
        });
      }
    } else {
      scheduleTimes.push({
        value: currentTime.toISOString(),
        time: new Date(currentTime.toISOString()),
        timeblockIndex: index,
        label: format(currentTime, 'p', {
          locale: getDeviceLangage().includes('fr') ? fr : enUS,
        }),
      });
    }

    minuteToAdd += 15;
  }
  return scheduleTimes;
}

export function isSameDay(dateA: Date, date2: Date) {
  return (
    dateA.getMonth() === date2.getMonth() &&
    dateA.getFullYear() === date2.getFullYear() &&
    dateA.getDate() === date2.getDate()
  );
}

function removeUnavailableValueBasedOnFrozenAvaibilities({
  timeItems,
  frozenAvailabilities,
  minTime,
}: {
  timeItems: TimeItem[];
  frozenAvailabilities: FrozenAvailability[];
  minTime?: string;
}) {
  let finalRes = cloneDeep(timeItems);

  if (!frozenAvailabilities.length) {
    // we dont need to do anything if that's the case.
    return finalRes;
  }

  // we remove all the impossible time value
  frozenAvailabilities.forEach((frozenAvailability) => {
    finalRes = finalRes.filter((timeItem) => {
      const currentTimeDate = new Date(timeItem.time);

      if (
        formatDateTimeIntoNumber(currentTimeDate) >
          formatDateTimeIntoNumber(new Date(frozenAvailability.startTime)) &&
        formatDateTimeIntoNumber(currentTimeDate) <
          formatDateTimeIntoNumber(new Date(frozenAvailability.endTime))
      ) {
        return false;
      }
      return true;
    });
  });

  if (!minTime) {
    // now we must allow 1h buffer between a frozen availability and a starting hour
    frozenAvailabilities.forEach((frozenAvailability) => {
      finalRes = finalRes.filter((timeItem) => {
        const currentTimeDate = new Date(timeItem.time);
        if (
          Math.abs(
            formatDateTimeIntoNumber(new Date(frozenAvailability.startTime)) -
              formatDateTimeIntoNumber(currentTimeDate),
          ) < 100
        ) {
          return false;
        }
        return true;
      });
    });
  } else if (minTime) {
    const minTimeNumberValue = formatDateTimeIntoNumber(new Date(minTime));

    // if there is a minTime - it means that we must compare the possible value based on the availabilities
    // e.i: if the min time is 11:00 and there is a training session reserved at 12:00 it means that everything
    // during that training session cannot be selected has an end time.

    // we order the frozenAvailabilities by their startTime
    const startTimes = frozenAvailabilities
      .map((frozenAvailability) =>
        formatDateTimeIntoNumber(new Date(frozenAvailability.startTime)),
      )
      .sort();

    let maxStartTime = startTimes.find(
      (startTime) => startTime > minTimeNumberValue,
    );

    if (!maxStartTime) {
      maxStartTime = 9999;
    }

    finalRes = finalRes.filter((timeItem) => {
      const currentTimeDate = new Date(timeItem.time);
      if (formatDateTimeIntoNumber(currentTimeDate) <= maxStartTime!) {
        return true;
      }
      return false;
    });
  }

  return finalRes;
}

/**
 * Takes a date and give a number value based on the hours and minutes
 * e.i: 08:30 ----> 830
 * e.i: 12:50 ----> 1250
 */
function formatDateTimeIntoNumber(date: Date) {
  const hoursStr = String(date.getHours());
  const minutes = date.getMinutes();
  let minutesStr = String(minutes);
  if (minutes < 10) {
    minutesStr = '0' + String(minutes);
  }

  return Number(hoursStr + minutesStr);
}

export function calculateNumberOfHour({
  to,
  from,
}: {
  to: string;
  from: string;
}) {
  const DateA = new Date();
  const DateB = new Date();

  const toDate = new Date(to);
  const fromDate = new Date(from);

  // @ts-ignore
  DateA.setHours(toDate.getHours());
  // @ts-ignore
  DateA.setMinutes(toDate.getMinutes());
  // @ts-ignore
  DateB.setHours(fromDate.getHours());
  // @ts-ignore
  DateB.setMinutes(fromDate.getMinutes());

  // @ts-ignore
  const diff = Math.abs(DateA - DateB);
  const timeDiff = Math.floor(diff / 1000 / 60);

  const nbOfHours = timeDiff / 60;
  return Math.round(nbOfHours);
}
