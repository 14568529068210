import styled from 'styled-components';

export const TextNormal = styled.span<{ css?: any }>`
  font-weight: 400;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.futura400}, sans-serif;
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.css}
`;

export const TextNormal500 = styled.span<{ css?: any }>`
  font-weight: 500;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.futura500}, sans-serif;
  color: ${(props) => props.theme.colors.gray};
  ${(props) => props.css}
`;

export const TextNormal600 = styled.span<{ css?: any }>`
  font-weight: 600;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.futura600}, sans-serif;
  color: ${(props) => props.theme.colors.gray};
  ${(props) => props.css}
`;

export const TextHeroTitle = styled.span<{ css?: any }>`
  text-align: right;
  font-weight: 600;
  font-size: 60px;
  font-family: ${(props) => props.theme.fonts.futura600}, sans-serif;
  color: ${(props) => props.theme.colors.white};
  ${(props) => props.css}
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    font-size: 32px;
  }
`;

export const TextTitle = styled.span<{ css?: any }>`
  font-weight: 600;
  font-size: 32px;
  font-family: ${(props) => props.theme.fonts.futura600}, sans-serif;
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.css}
`;

export const TextSubtitle = styled.span<{ css?: any }>`
  font-weight: 500;
  font-size: 22px;
  font-family: ${(props) => props.theme.fonts.futura500}, sans-serif;
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.css}
`;

export const TextSubtext = styled.span<{ css?: any }>`
  font-weight: 400;
  font-size: 12px;
  font-family: ${(props) => props.theme.fonts.futura400}, sans-serif;
  color: ${(props) => props.theme.colors.black};
  ${(props) => props.css}
`;
