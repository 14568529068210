import * as React from 'react';
import { Box } from 'react-native-kondo';
import { ClipLoader } from 'react-spinners';
import colors from '../../constants/colors';

const FittLoading = ({ style }: { style?: any }) => (
  <Box flex={1} alignItems="center" justifyContent="center" style={style}>
    <ClipLoader size={64} color={colors.secondary} loading={true} />
  </Box>
);

export default FittLoading;
