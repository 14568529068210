export default {
  'TrainerDetailsScreen.aboutMe': { en: 'About me', fr: 'À propos de moi' },
  'TrainerDetailsScreen.specializations': {
    en: 'Specializations',
    fr: 'Spécialisations',
  },
  'TrainerDetailsScreen.services': { en: 'Services', fr: 'Services' },
  'TrainerDetailsScreen.reviews': { en: 'Reviews', fr: 'Avis' },
  'TrainerDetailsScreen.cancellationPolicy': {
    en:
      'Any single session may be cancelled and\n            fully refunded up to 24 hours prior to the\n            booking date & time. Cancellations with\n            less than 24 hours notice cannot be\n            refunded.',
    fr:
      "Toute séance unique peut être annulée et\n            intégralement remboursée jusqu'à 24 heures avant la\n            date et l’heure de la réservation. Les annulations effectuées\n            moins de 24 heures à l'avance ne peuvent être\n            remboursées.",
  },
  'TrainerDetailsScreen.requestToBook': {
    en: 'Request to book',
    fr: 'Demande de réservation',
  },
  'TrainerDetailsScreen.messageFittcoach': {
    en: 'Message Fittcoach',
    fr: 'Envoyer un message au Fittcoach',
  },
  'TrainerDetailsScreen.bookpackageSessions': {
    en: 'Book package-sessions',
    fr: 'Réserver un forfait de séances',
  },
  'TrainerDetailsScreen.bookworkoutplan': {
    en: 'Book workout plan',
    fr: 'Réserver un programme d’entraînement',
  },
};
