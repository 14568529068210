import * as querystring from 'querystring';

import config from '../config';
import user from '../stores/user';
import { Id, TransactionDocument, UpdateSessionStatePayload } from '../types';
import { BAD_REQUEST, EXPIRED_TOKEN } from './restCodes';

const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
};

export function validateEmail(payload: { key: string }) {
  return post('/validateEmail', payload);
}

export function requestEmailValidation(payload: { key: string }) {
  return post('/requestConfirmEmail', payload);
}

export function editMeTrainer(payload: any) {
  return put('/me/trainer', payload);
}

export function trainerAddPicture(payload: any) {
  return put('/trainerPictures', payload);
}

export function trainerRemovePicture(payload: any) {
  return del('/trainerPictures', payload);
}

export function customScheduleAction(payload: any) {
  return post('/customScheduleAction', payload);
}

export function deleteSchedule(payload: any) {
  return del('/schedule', payload);
}

export function createSchedule(payload: any) {
  return post('/schedule', payload);
}

export function editSchedule(payload: any) {
  return put('/schedule', payload);
}

export function getSessions(payload?: {
  status: TransactionDocument['status'][];
}): {
  transactions: TransactionDocument[];
  totalTransactions: number;
  totalPages: number;
} {
  // @ts-ignore
  return get('/transactions', payload);
}

export function bookATrainer(payload: any) {
  return post('/bookATrainer', payload);
}

export function requestPasswordReset(payload: any) {
  return post('/requestResetPassword', payload);
}

export function resetPassword(payload: {
  resetKey: string;
  password: string;
  confirmPassword: string;
}) {
  return post('/resetPassword', payload);
}

export function login(payload: any) {
  return post('/login', payload);
}

export function loginWithGoogle(payload: any) {
  return post('/loginWithGoogle', payload);
}

export function loginWithFacebook(payload: any) {
  return post('/loginWithFacebook', payload);
}

export function signup(payload: any) {
  return post('/signup', payload);
}

export function getMe() {
  return get('/me');
}

export function getConversations(payload: any) {
  return get('/conversations', payload);
}

export function editMe(payload: any): any {
  return put('/me', payload);
}

export function editMeAuth(payload: any): any {
  return put('/meAuth', payload);
}

export function getGyms(payload: any) {
  return get('/gyms', payload);
}

export function getGym(payload: { gymId: Id }) {
  return get('/gym', payload);
}

export function fetchLocations(searchTerm: string) {
  return get('/locations', { searchTerm });
}

export function checkEmailAvailability(payload: { email: string }) {
  return post('/emailAvailability', payload);
}

export function becomeAFittcoach(payload: any) {
  return post('/becomeAFittcoach', payload);
}

export function initBecomeAFittcoach() {
  return post('/initBecomeAFittcoach');
}

export function addOrRemoveFavorites(payload: any) {
  return post('/favorites', payload);
}

export function getStripeAccountOnboardingURL() {
  return get('/stripeAccountOnboardingURL');
}

export function isStripeAccountValid() {
  return get('/isStripeAccountValid');
}

export function getTrainers(payload?: any) {
  return get('/trainers', payload);
}

export function getTrainer(payload: any) {
  return get('/trainer', payload);
}

export function updateSessionState(payload: UpdateSessionStatePayload) {
  return put('/updateSessionState', payload);
}

export function updateBankingInformation(payload: any) {
  return put('/me/bankingInformation', payload);
}

export function getTrainerSessions(payload?: {
  status: TransactionDocument['status'][];
}): {
  transactions: TransactionDocument[];
  totalTransactions: number;
  totalPages: number;
} {
  // @ts-ignore
  return get('/me/trainer/transactions', payload);
}

export function reverseGeocoding(payload: {
  latitude: number;
  longitude: number;
}) {
  return get('/reverseGeocoding', payload);
}

export function sendPushNotificationToken(payload: {
  pushToken: string;
  deviceName: string;
}) {
  return post('/pushNotificationToken', payload);
}

export function getPresignedURL({
  fileName = '',
  folder = '',
  extension = '',
}: {
  fileName?: string;
  folder?: string;
  extension?: string;
}) {
  return get('/getsignedurl', { fileName, folder, extension });
}

function post(url: string, data = {}) {
  return _fetch(config.apiURL + url, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

function put(url: string, data = {}) {
  return _fetch(config.apiURL + url, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(data),
  });
}

async function del(url: string, params = {}) {
  const queryString = '?' + querystring.stringify(params);
  const headers = await getHeaders();
  return _fetch(config.apiURL + url + queryString, {
    method: 'DELETE',
    headers,
  });
}

async function get(url: string, params = {}) {
  const queryString = '?' + querystring.stringify(params);
  const headers = await getHeaders();

  return _fetch(config.apiURL + url + queryString, {
    method: 'GET',
    headers,
  });
}

// @ts-ignore
async function _fetch(url: string, options: any) {
  const res = await fetch(url, options);

  if (res.status >= BAD_REQUEST) {
    if (res.status === EXPIRED_TOKEN) {
      const refreshToken = localStorage.getItem('rft');
      if (refreshToken) {
        try {
          const res2 = await fetch(`${config.apiURL}/login`, {
            method: 'POST',
            headers: getHeaders(true),
            body: JSON.stringify({ refreshToken: refreshToken! }),
          });

          const res2Json = await res2.json();
          await user.refreshAuth(res2Json);
          options.headers = getHeaders();
          return _fetch(url, options);
        } catch (e) {
          await user.refreshAuth({ forceLogout: true });
        }
      } else {
        await user.refreshAuth({ forceLogout: true });
      }
    } else {
      const resJson = await res.json();
      throw resJson.error;
    }
  }
  return res.json();
}

function getHeaders(noAuthorization = false) {
  const { token } = user;

  if (token && !noAuthorization) {
    return {
      ...BASE_HEADERS,
      authorization: `Bearer ${token}`,
    };
  }
  return BASE_HEADERS;
}

export function uploadMediaToAWS(
  presignedURL: string,
  file: any,
): Promise<XMLHttpRequest> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', presignedURL);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        resolve(xhr);
      } else if (xhr.status !== 200) {
        console.warn(xhr.response);
        reject(xhr.status);
      }
    };

    xhr.setRequestHeader('Content-Type', 'multipart/form-data');

    xhr.send(file);
  });
}
