export default {
  'goals.weightLoss': { en: 'Weight-Loss', fr: 'Perte de poids' },
  'goals.buildMuscle': { en: 'Build Muscle', fr: 'Renforcement musculaire' },
  'goals.getStronger': { en: 'Get Stronger', fr: 'Devenir plus fort' },
  'goals.improveEnduranceAndConditioning': {
    en: 'Improve Endurance/Conditioning',
    fr: 'Améliorer son endurance et sa condition physique',
  },
  'goals.improveAthleticskills': {
    en: 'Improve Athletic Skills',
    fr: 'Améliorer les compétences athlétiques',
  },
  'goals.improveJointFlexibility': {
    en: 'Improve Joint Flexibility',
    fr: 'Améliorer la souplesse des articulations',
  },
  'goals.competitionTraining': {
    en: 'Competition Training',
    fr: 'Entraînement à la compétition',
  },
  'goals.sportSpecificGoal': {
    en: 'Sport Specific Goal',
    fr: 'Objectif propre à un sport',
  },
};
