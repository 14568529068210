import translations from './translations/index';

export function getDeviceLangage() {
  const localStorageLanguage = localStorage.getItem('language');

  if (!localStorageLanguage) {
    // The default language is now french.
    return 'fr';
  }

  return localStorageLanguage === 'fr' ? 'fr' : 'en';
}

export function setDeviceLanguage(lang: 'fr' | 'en') {
  localStorage.setItem('language', lang);
  window.location.reload();
}

// i know this code is ugly.
// -Vince

export default function (
  translation: keyof typeof translations | typeof translations | string,
  defaultString = '',
) {
  const langKey = getDeviceLangage();

  if (typeof translation === 'object') {
    // @ts-ignore
    return translation[langKey] || defaultString;
  } else {
    // @ts-ignore
    return translations[translation] && translations[translation][langKey]
      ? // @ts-ignore
        // @ts-ignore
        translations[translation][langKey]
      : // @ts-ignore
      // @ts-ignore
      translations[translation] && translations[translation]['en']
      ? // @ts-ignore
        // @ts-ignore
        translations[translation]['en']
      : defaultString;
  }
}
