import { observable, action, toJS } from 'mobx';

import * as api from '../utils/api';
import { GymDocument } from '../types';
import { useStores } from '.';

export interface GymStore {
  gyms: GymDocument[];

  fetchGyms: (
    payload: {
      searchTerm?: string;
    } | void,
  ) => Promise<void>;

  getGyms: () => GymDocument[];

  isFetchingGyms: boolean;

  selectedGym?: GymDocument;
  getSelectedGym: () => GymDocument | undefined;
  setSelectedGym: (gym?: GymDocument) => void;
}

class Gym implements GymStore {
  @observable gyms: GymStore['gyms'] = [];

  @observable isFetchingGyms = false;

  @observable selectedGym: GymStore['selectedGym'];

  @action
  fetchGyms: GymStore['fetchGyms'] = async (payload = {}) => {
    try {
      this.isFetchingGyms = true;
      const gyms = await api.getGyms(payload);
      this.gyms = gyms;
    } finally {
      this.isFetchingGyms = false;
    }
  };

  getGyms: GymStore['getGyms'] = () => {
    return toJS(this.gyms);
  };

  @action
  setSelectedGym: GymStore['setSelectedGym'] = (gym) => {
    this.selectedGym = gym;
  };

  getSelectedGym: GymStore['getSelectedGym'] = () => {
    if (this.selectedGym) {
      return toJS(this.selectedGym);
    }
  };
}

const gymObj = new Gym();

export function useGymStore(): GymStore {
  const { gym } = useStores();
  return gym as GymStore;
}

export default gymObj;
