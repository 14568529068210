import { observable, action, toJS } from 'mobx';

import * as api from '../utils/api';
import { Id, TrainerDocument } from '../types';
import { useStores } from '.';
import gym from './gym';

export interface EditTrainerPayload {
  status: TrainerDocument['status'];
  trainerId: Id;
}

interface FetchParams {
  limit?: number;
}

export interface TrainerStore {
  totalTrainers: number;
  totalTrainerPages: number;
  currentTrainer: TrainerDocument | null;
  trainers: TrainerDocument[];

  fetchTrainers: (
    payload: {
      searchTerm?: string;
      page?: number;
      sort?: string;
      language?: 'en' | 'fr';
      limit?: number;
    } | void,
  ) => Promise<void>;
  fetchTrainer: (payload: { trainerId: Id }) => Promise<void>;
  getTrainers: () => TrainerDocument[];
  getCurrentTrainer: () => TrainerDocument | null;
  setCurrentTrainer: (trainer: TrainerDocument | null) => void;

  isFetchingTrainers: boolean;
  isFetchingTrainer: boolean;
}

class Trainer implements TrainerStore {
  @observable trainers: TrainerStore['trainers'] = [];
  @observable totalTrainers: TrainerStore['totalTrainers'] = 0;
  @observable totalTrainerPages: TrainerStore['totalTrainerPages'] = 1;
  @observable currentTrainer: TrainerStore['currentTrainer'] = null;

  @observable isFetchingTrainers = false;
  @observable isFetchingTrainer = false;

  @action
  fetchTrainer: TrainerStore['fetchTrainer'] = async (payload) => {
    try {
      this.isFetchingTrainer = true;
      const trainer = await api.getTrainer(payload);
      this.currentTrainer = trainer;
    } finally {
      this.isFetchingTrainer = false;
    }
  };

  getCurrentTrainer: TrainerStore['getCurrentTrainer'] = () => {
    if (this.currentTrainer) {
      return toJS(this.currentTrainer);
    }
    return null;
  };

  @action
  setCurrentTrainer: TrainerStore['setCurrentTrainer'] = (trainer) => {
    this.currentTrainer = trainer;
  };

  @action
  fetchTrainers: TrainerStore['fetchTrainers'] = async (payload = {}) => {
    try {
      this.isFetchingTrainers = true;

      const currentGym = gym.getSelectedGym();

      const { trainers, totalDocs, totalPages } = await api.getTrainers({
        limit: 100000,
        gyms: currentGym ? [currentGym.id] : undefined,
        ...payload,
      });

      this.trainers = trainers;
      this.totalTrainers = totalDocs;
      this.totalTrainerPages = totalPages;
    } finally {
      this.isFetchingTrainers = false;
    }
  };

  getTrainers: TrainerStore['getTrainers'] = () => {
    return toJS(this.trainers);
  };
}

const trainerObj = new Trainer();

export function useTrainerStore(): TrainerStore {
  const { trainer } = useStores();
  return trainer as TrainerStore;
}

export default trainerObj;
