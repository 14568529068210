import * as React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { TextSubtitle } from './Typography';

import i18n from '../../constants/i18n';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import images from '../../constants/images';

export default function FittSelector({
  label,
  value,
  options,
  onChange,
  placeholder,
  mainStyle,
}: {
  label?: string;
  value?: { label: string; value: any };
  onChange?: any;
  options?: { label: string; value: any }[];
  placeholder?: string;
  mainStyle?: React.CSSProperties;
}) {
  const colourStyles = {
    control: (styles: any) => {
      return {
        display: 'flex',
      };
    },
    option: (
      styles: any,
      { data, isDisabled, isFocused, isSelected }: any,
    ) => ({
      ...styles,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#00a39355',
      },
    }),
    input: (styles: any) => ({
      ...styles,
      borderWidth: 0,
      fontSize: 32,
      color: colors.primary,
      fontFamily: `${fonts.futura500}, sans-serif`,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      fontFamily: `${fonts.futura500}, sans-serif`,
      fontSize: 20,
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      padding: 0,
      borderBottomWidth: 1,
      borderColor: colors.black,
      cursor: 'pointer',
    }),
    singleValue: (styles: any) => ({
      ...styles,
      fontSize: 32,
      color: colors.primary,
      fontFamily: `${fonts.futura500}, sans-serif`,
      cursor: 'pointer',
      '&hover': {
        color: '',
      },
    }),
    dropdownIndicator: () => ({ opacity: 0 }),
    indicatorSeparator: () => ({ opacity: 0 }),
  };

  return (
    <Main style={mainStyle}>
      {label && (
        <TextSubtitle style={{ color: colors.gray }}>{label}</TextSubtitle>
      )}

      <Select
        // @ts-ignore
        styles={colourStyles}
        value={value}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        noOptionsMessage={() => i18n('FittSelector.nooptions', 'No match')}
      />
      <DownArrow alt="down arrow" src={images.icons.downArrow} />
    </Main>
  );
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const DownArrow = styled.img`
  width: 24px;
  height: 14px;
  position: absolute;
  top: 53px;
  right: 0px;
`;
