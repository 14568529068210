import * as React from 'react';
import { TrainerDocument } from '../../types';
import { Link } from 'react-router-dom';
import images from '../../constants/images';
import colors from '../../constants/colors';
import { TextSubtitle, TextNormal600, TextSubtext } from '../common/Typography';
import styled from 'styled-components';
import i18n from '../../constants/i18n';
import InfoBlock from './InfoBlock';
import { margins } from '../../constants/metrics';
import ISO6391 from 'iso-639-1';

function TrainerCell({
  trainer,
  showDetails,
  disableHover,
}: {
  trainer: TrainerDocument;
  showDetails?: boolean;
  disableHover?: boolean;
}) {
  const {
    id,
    user,
    services,
    pictures,
    avgRating,
    reviews,
    cardPicture,
  } = trainer;
  return (
    <Link
      to={`/trainer/${id}`}
      style={{
        textDecoration: 'none',
      }}
    >
      <Main
        style={showDetails ? {} : { height: 265 }}
        disableHover={disableHover}
      >
        <TrainerImg src={cardPicture || pictures[0]} alt="trainerImage" />
        <NameAndRate>
          <TextSubtitle style={{ fontWeight: 600 }}>
            {user.firstName + ' ' + user.lastName}
          </TextSubtitle>
          <TextNormal600 style={{ color: colors.primary }}>
            {`CA$${services.standardSession.priceInCents / 100} `}
            <TextSubtext style={{ color: colors.gray }}>
              {`/${i18n('TrainerCell.hour', 'hour')}`}
            </TextSubtext>
          </TextNormal600>
        </NameAndRate>

        {reviews.length ? (
          <ReviewRating>
            <StarImg src={images.icons.star} alt={'star'} />
            <TextSubtext style={{ color: colors.gray }}>
              {' '}
              {avgRating} {'(' + reviews.length + ')'}
            </TextSubtext>
          </ReviewRating>
        ) : null}

        {showDetails ? (
          <DetailsSection>
            <Column style={{ marginRight: margins[6] }}>
              <InfoBlock
                icon={images.icons.groupOfPeople}
                title={i18n('TrainerCell.groupsize', 'Group Size')}
                label={`${
                  trainer!.services.guestOptions.isAcceptingGuests
                    ? `1 - ${trainer!.services.guestOptions.nbOfGuests + 1}`
                    : '1'
                }`}
              />

              <Column style={{ marginBottom: margins[5] }} />

              <InfoBlock
                icon={images.icons.dumbbell}
                title={i18n('TrainerCell.trainat', 'Trains at')}
                label={
                  trainer!.selectedGyms.length === 1
                    ? trainer!.selectedGyms[0].name
                    : trainer!.selectedGyms.map((gym) => gym.name).join('\n')
                }
              />
            </Column>

            <Column>
              <InfoBlock
                icon={images.icons.speechBaloon}
                title={i18n('TrainerCell.language', 'Language')}
                label={trainer!.spokenLanguages
                  .map((lang) => `${ISO6391.getNativeName(lang)}`)
                  .join('\n')}
                labelStyle={{ width: 50 }}
              />
            </Column>
          </DetailsSection>
        ) : null}
      </Main>
    </Link>
  );
}

export default TrainerCell;
const Main = styled.div<{ disableHover?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 330px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.borders};
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 1px 5px -1px rgba(0, 0, 0, 0.1);
  padding-bottom: ${(props) => props.theme.paddingsCSS[2]};
  margin-bottom: ${(props) => props.theme.marginsCSS[8]};
  overflow: hidden;
  cursor: ${(props) => (props.disableHover ? 'default' : 'pointer')};
  transition: 0.2s ease-in-out;
  &:hover {
    transform: ${(props) =>
      props.disableHover ? 'scale(1, 1)' : 'scale(1.06, 1.06)'};
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
  }
`;

const TrainerImg = styled.img`
  height: 195px;
  object-fit: cover;
`;

const NameAndRate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${(props) => props.theme.paddingsCSS[1]};
  padding-left: ${(props) => props.theme.paddingsCSS[2]};
  padding-right: ${(props) => props.theme.paddingsCSS[2]};
`;

const ReviewRating = styled.div`
  display: flex;
  padding-left: ${(props) => props.theme.paddingsCSS[2]};
  padding-right: ${(props) => props.theme.paddingsCSS[2]};
`;

const StarImg = styled.img`
  margin-right: ${(props) => props.theme.paddingsCSS[1]};
`;

const DetailsSection = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${(props) => props.theme.paddingsCSS[5]};
  padding-right: ${(props) => props.theme.paddingsCSS[2]};
`;

const Column = styled.div`
  flex-direction: column;
  margin-right: ${(props) => props.theme.marginsCSS[4]};
`;
