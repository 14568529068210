import * as React from 'react';
import { Helmet } from 'react-helmet';
import { TextTitle } from '../common/Typography';
import styled from 'styled-components';
import ExplanationBox from './ExplanationBox';
import images from '../../constants/images';
import { margins, marginsCSS } from '../../constants/metrics';
import i18n from '../../constants/i18n';
import { useRouterStore } from '../../stores/router';

const HomeScreen = () => {
  const { savings, personalTrainer, healthyHabit } = images.icons;

  const router = useRouterStore();

  React.useEffect(() => {
    // we will now redirect to /explore
    // As we dont want to display this screen anymore
    router.push('/explore');
  }, []);

  const explanations = [
    {
      src: savings,
      title: i18n(
        'HomeScreen.affordablepersonaltraining',
        'Affordable Personal Training',
      ),
      body: i18n(
        'HomeScreen.oursessionsstartat',
        'Our sessions start at $25 per hour. We strive to make personal training affordable & accessible for anyone looking to get in shape',
      ),
    },
    {
      src: personalTrainer,
      title: i18n(
        'HomeScreen.variousworkoutoptions',
        'Various Workout Options',
      ),
      body: i18n(
        'HomeScreen.ourfittcoachspecialize',
        'Our fittcoaches specialize in various forms of exercise including bodybuiding, yoga, jujitsu, boxing and so much more',
      ),
    },
    {
      src: healthyHabit,
      title: i18n('HomeScreen.trainatour', 'Train at our Partners Gyms'),
      body: i18n(
        'HomeScreen.ourfittcoachwillcome',
        'Our fittcoaches will come directly to any of our partner gyms. Don’t see your gym on the list? Contact us to see if we can add it.',
      ),
    },
  ];

  return (
    <Main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fittcoach</title>
        <link rel="canonical" href="https://fittcoach.vercel.app" />
      </Helmet>
      <TextTitle style={{ alignSelf: 'center', marginBottom: margins[7] }}>
        {i18n('HomeScreen.howfittcoachworks', 'How Fittcoach Works')}
      </TextTitle>
      <ExplanationSection>
        {explanations.map((explanation) => {
          return <ExplanationBox key={explanation.title} {...explanation} />;
        })}
      </ExplanationSection>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${marginsCSS[6]};
  margin-bottom: 150px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-bottom: 0px;
  }
`;

const ExplanationSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
`;

export default HomeScreen;
