import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { TextSubtitle } from '../common/Typography';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import { BsArrowRight } from 'react-icons/bs';
import { observer } from 'mobx-react';
import { useUiStore } from '../../stores/ui';

const WelcomePopup = observer(() => {
  const uiStore = useUiStore();
  return (
    <View>
      <TextSubtitle style={{ marginBottom: 20 }}>
        {i18n(
          'WelcomePopup.alertTextP1',
          `For the best service, we strongly recommend you download our mobile app.`,
        )}
      </TextSubtitle>
      <TextSubtitle style={{ marginBottom: 20 }}>
        {i18n(
          'WelcomePopup.alertTextP2',
          `This web-app has very limited features and will only enable you to quickly book
            a single session with a fittcoach.`,
        )}
      </TextSubtitle>
      <TextSubtitle style={{ marginBottom: 20 }}>
        {i18n(
          'WelcomePopup.alertTextP3',
          `To message a fittcoach, use discounts, request workout plans, get packaged
            booking deals, and so much more, please download our mobile app.`,
        )}
      </TextSubtitle>
      <TextSubtitle style={{ marginBottom: 20 }}>
        {i18n(
          'WelcomePopup.alertTextP4',
          `For any assistance with downloading the app please contact us at
            `,
        )}{' '}
        <TouchableOpacity>
          <TextSubtitle style={{ color: 'blue', textDecoration: 'underline' }}>
            info@fittcoach.ca
          </TextSubtitle>
        </TouchableOpacity>
      </TextSubtitle>

      <View
        style={{
          flexDirection: uiStore.isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: uiStore.isMobile ? 20 : 0,
          }}
        >
          <Image
            source={images.icons.iconIos}
            style={{ height: 50, width: 50, borderRadius: 25 }}
          />
          <TextSubtitle style={{ marginLeft: 20, marginRight: 5 }}>
             {i18n('WelcomePopup.moreToLoveInTheApp', 'More to love in the app')}{' '}
          </TextSubtitle>
          <BsArrowRight size={20} />
        </View>
        <View
          style={{
            flexDirection: uiStore.isMobile ? 'column' : 'row',
            alignItems: 'center',
          }}
        >
          <TouchableOpacity
            onPress={() =>
              window.open(
                'https://play.google.com/store/apps/details?id=com.fittcoachapp.fittcoach',
              )
            }
          >
            <Image
              source={images.icons.getItPlayStore}
              style={{
                height: 50,
                width: 168,
                borderRadius: 15,
                marginBottom: uiStore.isMobile ? 10 : 0,
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              window.open(
                'https://apps.apple.com/ca/app/myfittcoach/id1524412889',
              )
            }
          >
            <Image
              source={images.icons.getItAppleStore}
              style={{
                height: 50,
                width: 168,
                marginLeft: uiStore.isMobile ? 0 : 20,
                borderRadius: 15,
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
});

export default WelcomePopup;
