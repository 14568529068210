import * as React from 'react';
import { TransactionDocument } from '../../types';
import images from '../../constants/images';

import colors from '../../constants/colors';
import {
  TextSubtitle,
  TextSubtext,
  TextNormal,
  TextNormal500,
  TextNormal600,
} from '../common/Typography';
import styled from 'styled-components';
import { format } from 'date-fns';
import i18n from '../../constants/i18n';
import { margins } from '../../constants/metrics';
 
export function getTransactionStatusWordingAndColor(
  transaction: TransactionDocument,
) {
  return {
    label: _getStatus(transaction),
    color: _getColor(transaction),
  };
}

const _getColor = (session: TransactionDocument) => {
  if (session.status === 'pending') {
    return colors.lightSecondary;
  } else if (session.status === 'accepted') {
    return colors.lightSecondary;
  } else {
    return colors.black;
  }
};

const _getStatus = (session: TransactionDocument) => {
  if (session.status === 'pending') {
    return i18n('TransactionCell.pending', 'Pending');
  } else if (session.status === 'accepted') {
    return i18n('TransactionCell.accepted', 'Accepted');
  } else if (session.status === 'cancelled') {
    return i18n('TransactionCell.cancelled', 'Cancelled');
  } else if (session.status === 'completed') {
    return i18n('TransactionCell.completed', 'Completed');
  } else if (session.status === 'rejected') {
    return i18n('TransactionCell.rejected', 'Rejected');
  } else if (session.status === 'expired') {
    return i18n('TransactionCell.expired', 'Expired');
  } else if (session.status === 'refunded') {
    return i18n('TransactionCell.refunded', 'Refunded');
  }
};

const getTemporalStatus = (session: TransactionDocument) => {
  if (session.status === 'accepted' || session.status === 'pending') {
    return {
      value: 'upcoming',
      label: i18n('TransactionCell.upcoming', 'Upcoming'),
    };
  } else {
    return {
      value: 'past',
      label: i18n('TransactionCell.past', 'Past'),
    };
  }
};

function TransactionCell({
  transaction,
  onPressCancel,
}: {
  transaction: TransactionDocument;
  onPressCancel: (transaction: TransactionDocument) => void;
}) {
  const { trainer, status, gym, service } = transaction;

  const temporalStatus = getTemporalStatus(transaction);

  const backgroundColor =
    status === 'pending'
      ? 'rgba(241, 93, 30, 0.1)'
      : status === 'accepted'
      ? 'rgba(0, 163, 147, 0.1)'
      : colors.borders;

  const statusAndColor = getTransactionStatusWordingAndColor(transaction);
  return (
    <Main
      css={`
        background-color: ${backgroundColor};
      `}
    >
      <TrainerImg src={trainer.pictures[0]} alt="trainerImage" />
      <InfoSection>
        <NameAndTemporalStatus>
          <TextSubtitle
            style={{
              color: colors.black,
              fontWeight: 600,
            }}
          >
            {`${trainer.user.firstName} ${trainer.user.lastName}`}
          </TextSubtitle>

          <TextSubtitle
            style={{
              color:
                temporalStatus.value === 'upcoming'
                  ? colors.paleGreen
                  : colors.black,
              fontSize: 18,
            }}
          >
            {temporalStatus.label}
          </TextSubtitle>
        </NameAndTemporalStatus>

        <GymSection>
          <IconAndText>
            <Image src={images.icons.location} alt="location" />

            <TextNormal style={{ color: colors.black }}>{gym.name}</TextNormal>
          </IconAndText>
          {temporalStatus.value === 'upcoming' ? (
            <TextSubtitle
              style={{
                fontSize: 18,
                color: statusAndColor.color,
              }}
            >
              {statusAndColor.label}
            </TextSubtitle>
          ) : null}
        </GymSection>

        <SessionsSection>
          {service.sessions.map((session) => {
            if (session.startDate && session.endDate) {
              return (
                <IconAndText>
                  <Image src={images.icons.calendar} alt="calendar" />

                  <TextNormal>
                    {`${format(
                      new Date(session.startDate),
                      'M/d/yy, h:mm a',
                    )} - ${format(new Date(session.endDate), 'h:mm a')}`}
                  </TextNormal>
                </IconAndText>
              );
            }
          })}
          {temporalStatus.value === 'upcoming' ? (
            <TextSubtext
              style={{
                marginBottom: margins[2],
                color: colors.error,
                cursor: 'pointer',
              }}
              onClick={() => onPressCancel(transaction)}
            >
              {i18n('TransactionCell.cancel', 'Cancel')}
            </TextSubtext>
          ) : null}
        </SessionsSection>
      </InfoSection>
    </Main>
  );
}

export default TransactionCell;

const Main = styled.div<{ css?: string }>`
  display: flex;
  flex-direction: row;
  width: 800px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.borders};
  border-radius: 10px 10px 0px 0px;
  margin-bottom: ${(props) => props.theme.marginsCSS[1]};
  ${(props) => props.css}

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction:column;
    margin-bottom: ${(props) => props.theme.marginsCSS[2]};
    border-radius: 10px;
  }
`;

const NameAndTemporalStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-right: ${(props) => props.theme.paddingsCSS[3]};
  padding-left: ${(props) => props.theme.paddingsCSS[1]};
`;

const GymSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-left: ${(props) => props.theme.marginsCSS[1]};
  margin-right: ${(props) => props.theme.marginsCSS[3]};
`;

const SessionsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.marginsCSS[1]};
  margin-right: ${(props) => props.theme.marginsCSS[3]};
`;

const IconAndText = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => props.theme.marginsCSS[2]};
`;

const TrainerImg = styled.img`
  width: 225px;
  height: 130px;
  border-radius: 10px 0px 0px 0px;
  object-fit: cover;

  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
  }
`;

const InfoSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 14;
  height: 14;
  margin-right: ${(props) => props.theme.marginsCSS[1]};
`;
