import { Provider } from 'mobx-react';
import * as React from 'react';
import { Router } from 'react-router';

import stores, { history } from '../stores';

import MainLayout from './layout/MainLayout';
import { ThemeProvider } from 'react-native-kondo';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import theme from '../constants/theme';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {
  render() {
    return (
      <Provider {...stores}>
        <Router history={history}>
          <StyledThemeProvider theme={theme}>
            <ThemeProvider theme={themeKondo}>
              <MainLayout />
            </ThemeProvider>
          </StyledThemeProvider>
        </Router>
      </Provider>
    );
  }
}

export default App;

const themeKondo = {
  borders: [],
  colors: {},
  fonts: {},
  fontSizes: [],
  letterSpacings: [],
  lineHeights: [],
  shadows: [],
  space: [],
};
