/* eslint-disable @typescript-eslint/no-unused-vars */
const dev = {
  // apiURL: 'http://192.168.0.107:1337',

  // This is aws's IP
  // apiURL: 'https://fittcoachprod.appandflow.com',
  apiURL: 'https://fittcoachstaging.appandflow.com',
  facebookAppId: '1198687570492321',
  googleClientId:
    '599998739645-om1hnbfhujfceo0qgmrgsmn85bbmisum.apps.googleusercontent.com',
  env: 'dev',
  // fitcoach's Stripe test key:
  stripePublishableKey: 'pk_test_cRlG42zoUmXRKuw8QbQZGL51',
};

const prod = {
  facebookAppId: '1198687570492321',
  googleClientId:
    '599998739645-om1hnbfhujfceo0qgmrgsmn85bbmisum.apps.googleusercontent.com',
  apiURL: 'https://fittcoachprod.appandflow.com',
  // apiURL: 'https://fittcoachstaging.appandflow.com',
  env: 'production',
  // fitcoach's Stripe live key:
  stripePublishableKey: 'pk_live_lReSbbXScpD68HCzUhq88Hxt',
};

const config = prod;

export default config;
