const icons = {
  fittcoachLogo: require('./images/FittcoachLogo.svg'),
  healthyHabit: require('./images/healthy_habit.svg'),
  personalTrainer: require('./images/personal_trainer.svg'),
  savings: require('./images/savings.svg'),
  star: require('./images/star.svg'),
  groupOfPeople: require('./images/groupOfPeople.svg'),
  dumbbell: require('./images/dumbbell.svg'),
  speechBaloon: require('./images/speechBaloon.svg'),
  downArrow: require('./images/downarrow.svg'),
  calendar: require('./images/calendar.svg'),
  location: require('./images/location.svg'),
  googleLogo: require('./images/googlelogo.svg'),
  facebookLogo: require('./images/facebooklogo.svg'),
  trainerAndClient: require('./images/trainerAndClient.png'),
  getItPlayStore: require('./images/google.png'),
  getItAppleStore: require('./images/apple.png'),
  wifi: require('./images/wifi.png'),
  iconIos: require('./images/iconIos.png'),
};

export default {
  icons,
  homeHero: require('./images/hero_landing.jpg'),
  verifycationImage: require('./images/verification.svg'),
};
