import React from 'react';
import { ClipLoader } from 'react-spinners';

import colors from '../../constants/colors';
import { TextNormal500 } from './Typography';

import styled from 'styled-components';

export interface ButtonProps {
  label?: string;
  style?: any;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  inverted?: boolean;
  rightIcon?: React.ReactNode;
  large?: boolean;
}

// export default function ({
//   label,
//   style,
//   onClick,
//   disabled,
//   loading,
//   inverted,
//   rightIcon,
//   large,
// }: ButtonProps) {
//   const ref = useRef(null);

//   const isHovered = useHover(ref);

//   const backgroundColor = 'transparent';
//   const textColor = inverted ? colors.black : colors.white;
//   const hoverColor = colors.lightPrimary;
//   const borderColor = disabled ? colors.borders : colors.borders;
//   const opacityHover = inverted ? 1 : 0.9;

//   return (
//     <div onClick={disabled || loading ? () => null : onClick}>
//       <View
//         ref={ref}
//         style={[
//           {
//             justifyContent: 'center',
//             alignItems: 'center',
//             backgroundColor: disabled ? colors.lightGray : backgroundColor,
//             // @ts-ignore
//             cursor: 'pointer',
//             borderRadius: 6,
//             borderWidth: 1,
//             borderColor: borderColor,
//             minHeight: large ? 67 : 30,
//             paddingHorizontal: 10,
//             minWidth: 182,
//           },
//           isHovered && !disabled && !loading
//             ? { backgroundColor: hoverColor, opacity: opacityHover }
//             : {},
//           style,
//         ]}
//       >
//         {label && !loading ? (
//           <Box flexDirection="row" alignItems="center">
//             {large ? (
//               <TextNormal style={{ color: textColor }}>{label}</TextNormal>
//             ) : (
//               <TextNormal style={{ color: textColor }}>{label}</TextNormal>
//             )}
//             {rightIcon ? rightIcon : null}
//           </Box>
//         ) : null}
//         {loading && <ClipLoader size={16} color={textColor} loading />}
//       </View>
//     </div>
//   );
// }

const Button = ({
  label,
  style,
  loading,
  onClick,
  secondary,
  inverted,
  disabled,
}: {
  label?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  loading?: boolean;
  secondary?: boolean;
  inverted?: boolean;
  disabled?: boolean;
}) => {
  let mainStyle: any = {};

  if (style) {
    mainStyle = style;
  }

  let css: any = {};

  if (secondary) {
    css = `
    background-color: ${colors.secondary};
    :hover {
        background-color: ${colors.lightSecondary};
      }`;
  }

  if (inverted) {
    css = `
    border: 2px solid ${colors.primary};
    background-color: ${colors.white};
    > span {
      color: ${colors.primary};
    }
    :hover {
      background-color: ${colors.primary};
    }
    :hover > span {
      color: ${colors.white};
    }
    `;
  }

  if (disabled) {
    css = `
    background-color: ${colors.lightGray};
    :hover {
        background-color: ${colors.lightGray};
      }`;
  }

  return (
    <Main style={mainStyle} onClick={disabled ? undefined : onClick} css={css}>
      {loading ? (
        <ClipLoader
          color={inverted ? colors.primary : colors.white}
          size={24}
        />
      ) : (
        <TextNormal500 style={{ color: inverted ? undefined : colors.white }}>
          {label}
        </TextNormal500>
      )}
    </Main>
  );
};

const Main = styled.div<{ css?: any }>`
  width: 185px;
  height: 46px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 10px;
  cursor: pointer;
  transition-timing-function: ease-in;
  transition: 0.2s;
  :hover {
    background-color: ${(props) => props.theme.colors.lightPrimary};
    transition-timing-function: ease-in;

    transition-timing-function: ease-out;
    transition: 0.25s;
  }
  ${(props) => props.css}
`;

export default Button;
