import * as React from 'react';
import { View } from 'react-native';
import { TextNormal } from './Typography';
import colors from '../../constants/colors';

export default ({
  style,
  value,
  onChange,
}: {
  style?: any;
  value?: boolean;
  onChange?: (newValue: boolean) => void;
}) => {
  return (
    <View
      // @ts-ignore
      onClick={() => onChange(!value)}
      style={[
        {
          borderRadius: 5,
          borderWidth: 1,
          borderColor: colors.black,
          backgroundColor: colors.white,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          height: 20,
          width: 20,
          cursor: 'pointer',
        },
        style,
      ]}
    >
      {value === true && (
        <View
          style={{
            flex: 1,
            backgroundColor: colors.primary,
            width: '100%',
            height: '100%',
          }}
        />
      )}
    </View>
  );
};
