import * as React from 'react';
import { View } from 'react-native';
import { TextNormal } from './Typography';
import ReactTooltip from 'react-tooltip';
import colors from '../../constants/colors';

const ToolTip = ({ text, style }: { text: string; style?: any }) => {
  return (
    <div data-tip={text} style={style}>
      <View
        // @ts-ignore
        style={{
          width: 20,
          height: 20,
          borderRadius: 20 / 2,
          backgroundColor: colors.lightPrimary,
          justifyContent: 'center',
          alignItems: 'center',
          // @ts-ignore
          cursor: 'pointer',
        }}
      >
        <TextNormal
          style={{ color: colors.white, fontSize: 16, marginRight: -1 }}
        >
          ?
        </TextNormal>
      </View>
      <ReactTooltip effect="solid" multiline className="opaquetooltip" />
    </div>
  );
};

export default ToolTip;
