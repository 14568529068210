import * as React from 'react';
import Calender from './Calender';
import { observer } from 'mobx-react';
import StripeCheckout, { Token } from 'react-stripe-checkout';
import { useTrainerStore } from '../../stores/trainer';

import styled from 'styled-components';
import FittLoading from '../common/FittLoading';
import FittBox from '../common/FittBox';

import FittSelector from '../common/FittSelector';
import i18n from '../../constants/i18n';
import { GymDocument, TrainerDocument } from '../../types';
import { margins } from '../../constants/metrics';
import {
  TextNormal,
  TextSubtext,
  TextSubtitle,
  TextTitle,
} from '../common/Typography';
import FittTextArea from '../common/FittTextArea';
import colors from '../../constants/colors';
import FittSelect from '../common/FittSelect';
import {
  calculateNumberOfHour,
  generateTimeValues,
  isSameDay,
} from '../../utils/timeSchedule';
import Button from '../common/Button';
import { useUserStore, TransactionPayload } from '../../stores/user';
import config from '../../config';
import { useUiStore } from '../../stores/ui';
import { formatError } from '../../utils/text';
import { useRouterStore } from '../../stores/router';

const BookingScreen = observer(() => {
  const trainerStore = useTrainerStore();
  const [selectedGym, setSelectedGym] = React.useState<
    GymDocument | undefined
  >();
  const [messageForTrainer, setMessageForTrainer] = React.useState('');
  const [isFetchingInit, setIsFetchingInit] = React.useState(true);
  const [isBookingATrainer, setIsBookingATrainer] = React.useState(false);
  const [to, setTo] = React.useState<any>();
  const [from, setFrom] = React.useState<any>();
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>();
  const [calenderKey, setCalenderKey] = React.useState(new Date().getTime());
  const userStore = useUserStore();
  const uiStore = useUiStore();
  const router = useRouterStore();

  React.useEffect(() => {
    const firstFetch = async () => {
      setIsFetchingInit(true);
      const urlString = window.location.href;
      const url = new URL(urlString);
      const trainerId = url.searchParams.get('trainerId');

      await trainerStore.fetchTrainer({
        trainerId: trainerId!,
      });
      setIsFetchingInit(false);
    };
    firstFetch();
  }, [trainerStore]);

  const currentTrainer = trainerStore.getCurrentTrainer()!;

  if (isFetchingInit || trainerStore.isFetchingTrainer) {
    return (
      <Main>
        <FittLoading
          style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}
        />
      </Main>
    );
  }

  // const sessions = this.props.booking.getSessions();

  // const currentSessionIndex = this.props.booking.currentSessionIndex;

  let schedule;

  if (selectedGym) {
    schedule = currentTrainer.schedules.find((s) =>
      s.gyms.includes(selectedGym.id),
    )!;
  }

  let selectedDateIndex = 0;
  let scheduleDay = schedule ? schedule.days[selectedDateIndex] : undefined;

  // @ts-ignore

  if (selectedDate) {
    selectedDateIndex = selectedDate!.getDay();
    selectedDateIndex = selectedDateIndex === 0 ? 6 : selectedDateIndex - 1;
    scheduleDay = schedule ? schedule.days[selectedDateIndex] : undefined;

    // now we check if it's actually a custom availability
    const customAvailability = currentTrainer.customAvailability;

    customAvailability.addedDays.forEach((addedDay) => {
      if (isSameDay(selectedDate, new Date(addedDay.date))) {
        scheduleDay = addedDay.schedules.find(
          // @ts-ignore
          (schedulecustom) => schedulecustom.gym === selectedGym.id,
        )!;
      }
    });
  }

  const onResponse = async (token: Token) => {
    setIsBookingATrainer(true);
    try {
      const transactionPayload: TransactionPayload = {
        trainerId: currentTrainer.id,
        sessions: [
          {
            skiped: false,
            index: 0,
            date: selectedDate,
            from: from,
            to: to,
            nbOfGuests: 0,
            nbOfHours: calculateNumberOfHour({
              to: to.value,
              from: from.value,
            }),

            toHour: new Date(to!.value).getHours(),
            toMinutes: new Date(to!.value).getMinutes(),
            fromHour: new Date(from!.value).getHours(),
            fromMinutes: new Date(from!.value).getMinutes(),
            scheduleLater: false,
          },
        ],

        promoCode: '',
        message: messageForTrainer,
        stripeCardToken: token.id,
        serviceType: '1session',
        gymId: selectedGym!.id,
        option: {
          priceInCents: currentTrainer.services.standardSession.priceInCents,
          price: currentTrainer.services.standardSession.price,
          nbOfSessions: 1,
          serviceType: '1session',
        },
      };
      await userStore.bookATrainer(transactionPayload);
      uiStore!.openAlert({
        title: i18n('BookingScreen.titleSuccess', 'Transaction Completed!'),
        text: i18n(
          'RecapScreen.finalmessage',
          `Your transaction has been approved, but it still needs to be accepted by the trainer. We'll notice you once it's accepted.`,
        ),
        buttons: [
          {
            label: i18n('WorkoutCell.ok', 'Ok'),
            onClick: () => {
              uiStore!.closeAlert();
            },
          },
        ],
      });
      router.push('/profile');
    } catch (e) {
      setIsBookingATrainer(false);
      uiStore!.openAlert({
        title: 'Error',
        text: formatError(e),
      });
    }
  };

  return (
    <Main>
      <FittBox style={{ flexDirection: 'column' }}>
        <TextTitle style={{ marginBottom: margins[8] }}>
          {i18n('BookingScreen.title', 'Book a session with')}{' '}
          {currentTrainer.user.firstName}
        </TextTitle>
        <FittSelector
          mainStyle={{ maxWidth: 600, marginBottom: margins[7] }}
          placeholder={i18n('BookingScreen.placeholder', 'Select gym')}
          label={i18n(
            'BookingScreen.label',
            'Where would you like to workout?',
          )}
          onChange={(selectedOption: any) => {
            setSelectedGym(selectedOption ? selectedOption.value : undefined);
            setSelectedDate(undefined);
            setTo(undefined);
            setFrom(undefined);
            setCalenderKey(new Date().getTime());
          }}
          value={
            selectedGym
              ? {
                  value: selectedGym,
                  label: `${
                    selectedGym.company ? selectedGym.company.name + ' ' : ''
                  }${selectedGym.name} - ${selectedGym.location.address1}`,
                }
              : undefined
          }
          options={currentTrainer.selectedGyms.map((gym) => ({
            value: gym,
            label: `${gym.company ? gym.company.name + ' ' : ''}${gym.name} - ${
              gym.location.address1
            }`,
          }))}
        />

        <TextSubtitle
          style={{ opacity: selectedGym ? 1 : 0.3, marginBottom: margins[4] }}
        >
          {i18n('BookingScreen.labelCalender', 'When do you want to train?')}
        </TextSubtitle>

        <Calender
          key={String(calenderKey)}
          currentGymId={selectedGym ? selectedGym.id : undefined}
          nbOfDateToSelect={1}
          onChangeSelectedDates={(selectedDates) => {
            setSelectedDate(selectedDates[0]);
            setTo(undefined);
            setFrom(undefined);
          }}
          trainerSchedule={schedule}
          customAvailability={currentTrainer.customAvailability}
          style={{ maxWidth: 600, marginBottom: margins[7] }}
          disabled={!selectedGym}
        />

        <Row>
          <FittSelect
            value={from ? from!.label : undefined}
            label={i18n('BookingScreen.from', 'From')}
            items={
              selectedDate
                ? generateTimeValues({
                    scheduleDay: scheduleDay!,
                    frozenAvailabilities: currentTrainer.frozenAvailabilities.filter(
                      (frozenAvailability: any) => {
                        return isSameDay(
                          new Date(frozenAvailability.date),
                          selectedDate,
                        );
                      },
                    ),
                  })
                : []
            }
            // @ts-ignore
            onChange={(item) => {
              setFrom(item);
              setTo(undefined);
            }}
            disabled={!selectedDate}
          />
          <div style={{ width: 40 }} />
          <FittSelect
            value={to ? to!.label : undefined}
            label={i18n('BookingScreen.to', 'To')}
            items={
              selectedDate && from
                ? generateTimeValues({
                    scheduleDay: scheduleDay!,
                    timeblockIndex: from!.timeblockIndex,
                    minTime: from!.value,
                    frozenAvailabilities: currentTrainer.frozenAvailabilities.filter(
                      (frozenAvailability: any) => {
                        return isSameDay(
                          new Date(frozenAvailability.date),
                          selectedDate,
                        );
                      },
                    ),
                  })
                : []
            }
            // @ts-ignore
            onChange={(item) => setTo(item)}
            disabled={!from}
          />
        </Row>

        <TextSubtitle style={{ marginBottom: margins[2] }}>
          {i18n('BookingScreen.textarean', 'Introduce yourself')}
          <TextSubtext style={{ color: colors.gray }}>
            {' '}
            ({i18n('BookingScreen.optional', 'Optional')})
          </TextSubtext>
        </TextSubtitle>
        <FittTextArea
          value={messageForTrainer}
          onChangeText={setMessageForTrainer}
          style={{ maxWidth: 600, height: 110, marginBottom: margins[7] }}
          inputStyle={{ height: 110, width: '100%' }}
        />

        {to && from ? (
          <>
            <RowCash>
              <TextNormal>
                {i18n('BookingScreen.pricePerHour', 'Price per hour')}
              </TextNormal>
              <TextNormal style={{ color: colors.secondary }}>
                {currentTrainer.services.standardSession.price}
              </TextNormal>
            </RowCash>

            <RowCash>
              <TextNormal>{i18n('BookingScreen.hour', 'Hour(s)')}</TextNormal>
              <TextNormal style={{ color: colors.secondary }}>
                {calculateNumberOfHour({ to: to.value, from: from.value })}
              </TextNormal>
            </RowCash>

            <RowCash>
              <TextNormal>
                {i18n('BookingScreen.QST', 'QST')}
                {` (${currentTrainer.taxInformation.qst}) `}
              </TextNormal>
              <TextNormal style={{ color: colors.secondary }}>
                {
                  _calculatePrices({
                    to: to.value,
                    from: from.value,
                    trainer: currentTrainer,
                  }).taxQSTTotal
                }
              </TextNormal>
            </RowCash>

            <RowCash>
              <TextNormal>
                {i18n('BookingScreen.GST', 'GST')}
                {` (${currentTrainer.taxInformation.gst}) `}
              </TextNormal>
              <TextNormal style={{ color: colors.secondary }}>
                {
                  _calculatePrices({
                    to: to.value,
                    from: from.value,
                    trainer: currentTrainer,
                  }).taxGSTTotal
                }
              </TextNormal>
            </RowCash>

            <RowCash style={{ marginBottom: margins[3] }}>
              <TextNormal>{i18n('BookingScreen.taxes', 'Taxes')}</TextNormal>
              <TextNormal style={{ color: colors.secondary }}>
                {
                  _calculatePrices({
                    to: to.value,
                    from: from.value,
                    trainer: currentTrainer,
                  }).tax
                }
              </TextNormal>
            </RowCash>

            <RowCash style={{ marginBottom: margins[12] }}>
              <TextTitle>{i18n('BookingScreen.total', 'Total')}</TextTitle>
              <TextTitle style={{ color: colors.secondary }}>
                {
                  _calculatePrices({
                    to: to.value,
                    from: from.value,
                    trainer: currentTrainer,
                  }).grandTotal
                }
              </TextTitle>
            </RowCash>

            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <StripeCheckout
                token={onResponse}
                stripeKey={config.stripePublishableKey}
                email={userStore.getMe()!.email}
                currency="CAD"
                amount={
                  _calculatePrices({
                    to: to.value,
                    from: from.value,
                    trainer: currentTrainer,
                  }).grandTotalCents
                }
              >
                <Button
                  style={{ width: 300 }}
                  secondary
                  loading={isBookingATrainer}
                  label={i18n('BookingScreen.pay', 'Pay and Book')}
                />
              </StripeCheckout>
            </div>
          </>
        ) : null}
      </FittBox>
    </Main>
  );
});

const _calculatePrices = ({
  to,
  from,
  trainer,
}: {
  to: string;
  from: string;
  trainer: TrainerDocument;
}) => {
  const totalNbOfHours = calculateNumberOfHour({ to, from });
  const totalPreTax =
    totalNbOfHours * trainer.services.standardSession.priceInCents;
  const taxGST = totalPreTax * 0.05;
  const taxGSTString = (taxGST / 100).toFixed(2);
  const taxQST = totalPreTax * 0.09975;
  const taxQSTString = (taxQST / 100).toFixed(2);
  const tax = taxGST + taxQST;
  const grandTotal = ((tax + totalPreTax) / 100).toFixed(2);
  const grandTotalCents = parseFloat(grandTotal) * 100;
  return {
    tax: `$${(tax / 100).toFixed(2)}`,
    taxGSTTotal: `$${taxGSTString}`,
    taxQSTTotal: `$${taxQSTString}`,
    grandTotal: `$${grandTotal}`,
    grandTotalCents,
  };
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.safeTopDistance}px;
  padding-bottom: 100px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.marginsCSS[7]};
`;

const RowCash = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 350px;
`;

export default BookingScreen;
