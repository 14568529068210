import * as React from 'react';
import styled from 'styled-components';
import FittBox from '../common/FittBox';
import images from '../../constants/images';
import { TextNormal500 } from '../common/Typography';
import i18n from '../../constants/i18n';
import { margins } from '../../constants/metrics';
import colors from '../../constants/colors';
import Button from '../common/Button';
import { useRouterStore } from '../../stores/router';
import { useUiStore } from '../../stores/ui';
import { formatError } from '../../utils/text';
import {
  validateEmail,
  requestEmailValidation,
  resetPassword,
} from '../../utils/api';
import { ClipLoader } from 'react-spinners';
import FittTextInput from '../common/FittTextInput';
import delay from '../../utils/delay';

const _checkIfPasswordIsValid = (password: string) => {
  const regex = /\d/;
  if (password?.length < 6 || !password.match(regex)) {
    return false;
  }
  return true;
};

const ResetPasswordScreen = () => {
  const [loading, setLoading] = React.useState(true);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [resetKey, setKey] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const uiStore = useUiStore();

  React.useEffect(() => {
    const validate = () => {
      try {
        setLoading(true);
        const urlString = window.location.href;
        const url = new URL(urlString);
        const key = url.searchParams.get('resetKey');
        if (!key) {
          throw new Error(
            i18n(
              'ResetPasswordScreen.keynotfound',
              'Your reset key is invalid or expired.',
            ),
          );
        }
        setKey(key);
      } catch (e) {
        uiStore.openToaster({
          type: 'error',
          text: formatError(e),
        });
      } finally {
        setLoading(false);
      }
    };
    validate();
  }, []);

  const onClickUpdatePassword = async () => {
    if (!_checkIfPasswordIsValid(password)) {
      uiStore.openToaster({
        type: 'error',
        text: i18n(
          `LoginView.incorrectPassword`,
          `Password must have at least 6 characters and include at least one number.`,
        ),
      });
      return;
    }
    try {
      setButtonLoading(true);
      await resetPassword({
        resetKey,
        confirmPassword,
        password,
      });
      uiStore.openToaster({
        text: i18n(
          'ResetPasswordScreen.success',
          'Your password has been reset.',
        ),
      });
      await delay(4000);
      window.location.href = 'https://www.fittcoach.com';
    } catch (e) {
      console.error(e);
      uiStore.openToaster({
        type: 'error',
        text: i18n(
          'ResetPasswordScreen.keynotfound',
          'Your reset key is invalid or expired.',
        ),
      });
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <Main>
      <FittBox
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingTop: margins[10],
          paddingBottom: margins[10],
        }}
      >
        <FittTextInput
          label={i18n('ResetPasswordScreen.password', 'New password')}
          style={{ marginBottom: margins[6], width: 300 }}
          value={password}
          onChangeText={setPassword}
          type="password"
          inputStyle={{ width: '100%' }}
        />
        <FittTextInput
          label={i18n(
            'ResetPasswordScreen.confirmpassword',
            'Confirm new password',
          )}
          style={{ marginBottom: margins[6], width: 300 }}
          value={confirmPassword}
          onChangeText={setConfirmPassword}
          type="password"
          inputStyle={{ width: '100%' }}
        />

        <Button
          label={i18n('ResetPasswordScreen.updatePassword', 'Update Password')}
          style={{
            marginTop: margins[4],
            marginBottom: margins[4],
            width: 300,
          }}
          onClick={onClickUpdatePassword}
          loading={buttonLoading || loading}
          disabled={!confirmPassword.length || password !== confirmPassword}
        />

        {loading && <ClipLoader size={32} color={colors.primary} />}
      </FittBox>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.paddings[6]}px;
`;

const ImageVerification = styled.img`
  margin-bottom: ${(props) => props.theme.margins[6]}px;
`;

export default ResetPasswordScreen;
