import * as React from 'react';
import { Box } from 'react-native-kondo';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { margins } from '../../constants/metrics';
import { TextNormal500, TextSubtitle } from '../common/Typography';
import colors from '../../constants/colors';
import { getDeviceLangage } from '../../constants/i18n';

import { format } from 'date-fns';

import { Schedule, TrainerDocument, GymDocument, Id } from '../../types';
import { isSameDay } from '../../utils/timeSchedule';
import { enCA, frCA } from 'date-fns/locale';
 
export interface CombinedSchedule {
  days: CombinedDaySchedule[];
}

interface CombinedDaySchedule {
  gyms: GymDocument[];
  timeblocks: { from: string; to: string; gyms: GymDocument[] }[];
}

function formatTrainerSchedules(
  trainerSchedules: TrainerDocument['schedules'],
) {
  const final: CombinedSchedule = {
    days: Array.from({ length: 7 }, (_, i) => ({
      gyms: [],
      timeblocks: [],
      indexDay: i,
    })),
  };

  trainerSchedules.forEach((schedule) => {
    schedule.days.forEach((day, dayIndex) => {
      // @ts-ignore
      final.days[dayIndex].timeblocks = [
        ...final.days[dayIndex].timeblocks,
        ...day.timeblocks,
      ];

      final.days[dayIndex].timeblocks.forEach((t, timeblockIndex) => {
        if (!final.days[dayIndex].timeblocks[timeblockIndex].gyms) {
          final.days[dayIndex].timeblocks[timeblockIndex].gyms = [];
        }

        final.days[dayIndex].timeblocks[timeblockIndex] = {
          ...final.days[dayIndex].timeblocks[timeblockIndex],
          // @ts-ignore
          gyms: [
            ...final.days[dayIndex].timeblocks[timeblockIndex].gyms,
            ...schedule.gyms,
          ],
        };
      });

      // @ts-ignore
      final.days[dayIndex].gyms = [
        ...final.days[dayIndex].gyms,
        ...schedule.gyms,
      ];
    });
  });

  return final;
}

interface P {
  nbOfDateToSelect?: number;
  onChangeSelectedDates?: (selectedDates: Date[]) => void;
  selectedDate?: Date;
  trainerSchedule?: Schedule;
  trainerSchedules?: TrainerDocument['schedules'];
  onChangeSelectedCombinedDay?: (day: CombinedDaySchedule) => void;

  customAvailability?: TrainerDocument['customAvailability'];
  canSelectedEveryDate?: boolean;
  getRef?: (ref: Calender) => void;
  currentGymId?: Id;
  style?: any;
  disabled?: boolean;
}

const isSameCalendarDay = (date1: Date, date2: Date) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  );
};

export default class Calender extends React.Component<
  P,
  {
    selectedDates: Date[];
    currentMonthIndex: number;
    firstDay: Date;
    index: number;
    selectedDayItem?: CombinedDaySchedule;
    init: boolean;
    calendarData: any[];
  }
> {
  state = {
    selectedDayItem: undefined,
    currentMonthIndex: 0,
    index: 0,
    calenderIndex: 0,
    selectedDates: this.props.selectedDate
      ? [new Date(this.props.selectedDate.toString())]
      : [],
    firstDay: new Date(),
    init: true,
    calendarData: [],
  };
  _calenderData: any = [];

  componentDidMount() {
    this._calculateCalenderData();

    if (this.props.getRef) {
      this.props.getRef(this);
    }
  }

  componentDidUpdate(oP: P) {
    if (
      oP.currentGymId !== this.props.currentGymId &&
      this.props.currentGymId
    ) {
      this._calculateCalenderData();
    }
  }

  _calculateCalenderData = () => {
    const currentDate = new Date();
    let firstDay = new Date();
    const currentMonthIndex = currentDate.getMonth();
    const calender = [];

    while (currentDate.getDate() !== 1) {
      currentDate.setDate(currentDate.getDate() - 1);
    }

    firstDay = new Date(currentDate);

    while (currentDate.getDay() !== 0) {
      currentDate.setDate(currentDate.getDate() - 1);
    }

    const monthsToAdd = 12;
    for (let i = 0; i < monthsToAdd; i++) {
      const newDate = new Date(
        firstDay.getFullYear(),
        currentMonthIndex + i,
        1,
      );
      const year = newDate.getFullYear();

      let month = currentMonthIndex + i;

      if (month >= 12) {
        month = month - 12;
      }

      calender.push(this._getMonthData({ month, year }));
    }

    this._calenderData = calender;

    this.setState({
      currentMonthIndex,
      firstDay,
      init: false,
      calendarData: calender,
    });
  };

  _getMonthData = ({ month, year }: { month: number; year: number }) => {
    const monthData: Date[] = [];
    const firstDayOfTheMonth = new Date(year, month, 1);

    while (firstDayOfTheMonth.getDay() !== 0) {
      firstDayOfTheMonth.setDate(firstDayOfTheMonth.getDate() - 1);
    }

    const dayToAdd = 35;

    for (let i = 0; i < dayToAdd; i++) {
      const day = new Date(firstDayOfTheMonth.toString());
      day.setDate(day.getDate() + i);
      monthData.push(day);
    }

    const lastDayOfTheMonth = new Date(monthData[monthData.length - 1]);

    while (lastDayOfTheMonth.getMonth() === monthData[15].getMonth()) {
      lastDayOfTheMonth.setDate(lastDayOfTheMonth.getDate() + 1);
      const day = new Date(lastDayOfTheMonth.toString());
      monthData.push(day);
    }

    while (monthData.length !== 42) {
      lastDayOfTheMonth.setDate(lastDayOfTheMonth.getDate() + 1);
      const day = new Date(lastDayOfTheMonth.toString());
      monthData.push(day);
    }

    return monthData;
  };

  _handleDateSelection = (date: Date, dayItem?: CombinedDaySchedule) => {
    let selectedDates: Date[] = [...this.state.selectedDates];

    if (this.props.nbOfDateToSelect === 1) {
      selectedDates = [date];
    } else {
      const index = selectedDates.findIndex((s) => s === date);
      if (index === -1) {
        selectedDates.push(date);
      } else {
        selectedDates.splice(index, 1);
      }
    }

    this.setState({ selectedDates, selectedDayItem: dayItem }, () => {
      if (this.props.onChangeSelectedDates) {
        this.props.onChangeSelectedDates(selectedDates);
      }

      if (this.props.onChangeSelectedCombinedDay && dayItem) {
        const removed = this._isInCustomAvailability({
          dateToCheck: date,
        });
        const added = this._isInCustomAddedAvailability({
          dateToCheck: date,
        });

        if (removed) {
          this.props.onChangeSelectedCombinedDay({
            gyms: [],
            timeblocks: [],
          });
        } else if (added) {
          // @ts-ignore
          const formatedSchedule = added.schedules.map((s) => ({
            // @ts-ignore
            gyms: [s.gym],
            days: [
              {
                // @ts-ignore
                timeblocks: s.timeblocks.map((t) => ({ ...t, gyms: [s.gym] })),
              },
            ],
          }));
          this.props.onChangeSelectedCombinedDay({
            // @ts-ignore
            gyms: added.schedules.map((s) => s.gym),
            // @ts-ignore
            timeblocks: added.schedules
              // @ts-ignore
              .map((s, i) => formatedSchedule[i].days[0].timeblocks)
              .flat(),
          });
        } else {
          this.props.onChangeSelectedCombinedDay(dayItem);
        }
      }
    });
  };

  _getDaysOfTheWeekLetters = () => {
    if (getDeviceLangage() === 'fr') {
      return ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
    } else {
      return ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    }
  };

  _renderDaysRow = () => {
    return (
      <Box pb={8} style={{ borderBottomWidth: 1, borderColor: colors.borders }}>
        <Box
          // px={metrics.padding}
          justifyContent="space-between"
          flexDirection="row"
        >
          {this._getDaysOfTheWeekLetters().map((day, i) => (
            <DayLetter key={`${day}-${i}-days`} dayLetter={day} />
          ))}
        </Box>
      </Box>
    );
  };

  _renderCalender = ({ item }: { item: any }) => {
    let offset = 0;
    const max = 7;

    const dateToCheck = new Date(item[0]);

    while (dateToCheck.getDate() !== 1) {
      dateToCheck.setDate(dateToCheck.getDate() + 1);
    }

    const month = dateToCheck.getMonth();
    const today = new Date();

    const nbOfRow = item.length > 35 ? 6 : 5;

    const { trainerSchedule, trainerSchedules } = this.props;

    let scheduleToUse = trainerSchedule;

    if (trainerSchedules) {
      // @ts-ignore
      scheduleToUse = formatTrainerSchedules(trainerSchedules);
    }

    return (
      <Box>
        {Array.from({ length: nbOfRow }, (v, i) => (
          <Box
            key={`${i}-roww`}
            flexDirection="row"
            justifyContent="space-between"
            mb={4}
          >
            {item.slice(offset, offset + max).map((date: Date, ii: number) => {
              if (offset + ii === offset + max - 1) {
                offset = offset + max;
              }

              const daySchedule = ii === 0 ? 6 : ii - 1;

              return (
                <DayItem
                  // @ts-ignore
                  selected={this.state.selectedDates.find((ss) =>
                    isSameCalendarDay(ss, date),
                  )}
                  // To whom ever may maintain this.
                  // I am so sorry.
                  // -Vince
                  // @ts-ignore
                  notAvailable={
                    (!scheduleToUse ||
                      !scheduleToUse!.days[daySchedule].timeblocks.length ||
                      this._isInCustomAvailability({
                        dateToCheck: date,
                        // @ts-ignore
                        considerGym:
                          scheduleToUse &&
                          scheduleToUse!.days[daySchedule].timeblocks.length,
                      })) &&
                    !this._isInCustomAddedAvailability({
                      dateToCheck: date,
                    })
                  }
                  onPress={() =>
                    this._handleDateSelection(
                      date,
                      // @ts-ignore
                      scheduleToUse
                        ? scheduleToUse!.days[daySchedule]
                        : undefined,
                    )
                  }
                  disabled={this.props.disabled}
                  canSelectedEveryDate={this.props.canSelectedEveryDate}
                  key={date.toString()}
                  date={date.getDate()}
                  notPartOfTheMonth={
                    date.getMonth() !== month ||
                    (date.getMonth() === today.getMonth() &&
                      date.getFullYear() === today.getFullYear() &&
                      date.getDate() < today.getDate())
                  }
                />
              );
            })}
          </Box>
        ))}
      </Box>
    );
  };

  _isInCustomAvailability = ({
    dateToCheck,
    considerGym = false,
  }: {
    dateToCheck: Date;
    considerGym?: boolean;
  }) => {
    const { customAvailability } = this.props;

    if (!customAvailability) {
      return false;
    }

    const exist = customAvailability.removedDays.find((removedDay) => {
      const removedDayDate = new Date(removedDay.date);

      return isSameDay(removedDayDate, dateToCheck);
    });

    // special case where we may need to return true even if it's not really into
    // the removed date
    if (considerGym && !exist) {
      return this._isInCustomAddedAvailability({
        dateToCheck,
        ignoreGym: true,
      });
    }

    return exist !== undefined;
  };

  _isInCustomAddedAvailability = ({
    dateToCheck,
    ignoreGym = false,
  }: {
    dateToCheck: Date;
    ignoreGym?: boolean;
  }) => {
    const { customAvailability, currentGymId } = this.props;

    if (!customAvailability) {
      return false;
    }

    const exist = customAvailability.addedDays.find((addedDay) => {
      const addedDayDate = new Date(addedDay.date);
      return isSameDay(addedDayDate, dateToCheck);
    });

    if (currentGymId && exist && !ignoreGym) {
      return exist.schedules.filter(
        // @ts-ignore
        (s) => s.gym === currentGymId && s.timeblocks.length,
      ).length;
    }

    return exist;
  };

  _onPressBack = () => {
    const { index } = this.state;
    if (index > 0) {
      this.setState({ index: index - 1 });
    }
  };

  _onPressNext = () => {
    const { index } = this.state;
    if (index < this._calenderData.length - 1) {
      this.setState({ index: index + 1 });
    }
  };

  _renderMonthRow = () => {
    const { firstDay, index } = this.state;

    const newDate = new Date(
      firstDay.getFullYear(),
      firstDay.getMonth() + index,
      1,
    );

    const canScrollBack = this.state.index > 0;
    const canScrollNext = this.state.index < this._calenderData.length - 1;

    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        // px={metrics.padding}
        py={22}
      >
        <TextSubtitle>
          {format(new Date(newDate), 'MMMM yyyy', {
            locale: getDeviceLangage() === 'fr' ? frCA : enCA,
          })}
        </TextSubtitle>

        <Box flexDirection="row" alignItems="center">
          <IoIosArrowBack
            size={20}
            style={{
              marginRight: margins[4],
              cursor: 'pointer',
              opacity: canScrollBack ? 1 : 0.3,
            }}
            color={colors.primary}
            onClick={this._onPressBack}
          />

          <IoIosArrowForward
            size={20}
            style={{ cursor: 'pointer', opacity: canScrollNext ? 1 : 0.3 }}
            color={colors.primary}
            onClick={this._onPressNext}
          />
        </Box>
      </Box>
    );
  };

  render() {
    if (this.state.init) {
      return <div />;
    }

    let style: any = {};
    if (this.props.style) {
      style = { ...this.props.style };
    }

    if (this.props.disabled) {
      style['opacity'] = 0.3;
    }

    return (
      <Box bg={colors.white} style={style}>
        {this._renderDaysRow()}
        {this._renderMonthRow()}
        {this._renderCalender({
          item: this.state.calendarData[this.state.index],
        })}
      </Box>
    );
  }
}

const DayLetter = ({ dayLetter }: { dayLetter: string }) => (
  <Box width={40} height={40} justifyContent="center" alignItems="center">
    <TextNormal500 style={{ color: colors.black }}>
      {dayLetter.toUpperCase()}
    </TextNormal500>
  </Box>
);

const DayItem = ({
  date,
  selected = false,
  notAvailable = false,
  notPartOfTheMonth = false,
  onPress,
  canSelectedEveryDate,
  disabled,
}: {
  disabled?: boolean;
  date: number;
  selected?: boolean;
  notAvailable?: boolean;
  notPartOfTheMonth?: boolean;
  onPress?: () => void;
  canSelectedEveryDate?: boolean;
}) => {
  let fontColor = colors.black;

  if (notPartOfTheMonth || notAvailable) {
    fontColor = colors.gray;
  }

  if (selected) {
    fontColor = colors.white;
    if ((notPartOfTheMonth || notAvailable) && canSelectedEveryDate) {
      fontColor = colors.gray;
    }
  }

  let borderColor = colors.borders;

  if (notPartOfTheMonth || notAvailable) {
    borderColor = 'transparent';
  }

  if (selected) {
    borderColor = colors.secondary;
  }

  return (
    <Box
      onClick={
        canSelectedEveryDate
          ? notPartOfTheMonth
            ? null
            : onPress
          : notPartOfTheMonth || notAvailable || disabled
          ? null
          : onPress
      }
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
        backgroundColor:
          selected && !notAvailable ? colors.secondary : 'transparent',
        borderWidth: 1,
        borderColor,
        // @ts-ignore
        cursor: canSelectedEveryDate
          ? notPartOfTheMonth
            ? undefined
            : 'pointer'
          : notPartOfTheMonth || notAvailable
          ? undefined
          : 'pointer',
      }}
    >
      <TextNormal500 style={{ color: fontColor, textAlign: 'center' }}>
        {date}
      </TextNormal500>
      {notAvailable && !notPartOfTheMonth ? (
        <Box
          bg={colors.gray}
          height={30}
          width={1}
          style={{
            alignSelf: 'center',
            position: 'absolute',
            top: 5,
            right: 0,
            left: 20,
            bottom: 0,
            transform: [{ rotate: '45deg' }],
          }}
        />
      ) : null}
    </Box>
  );
};
