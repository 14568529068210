import { observable, action, toJS } from 'mobx';

import metrics from '../constants/metrics';
import { ToasterOptions } from '../types';
import { useStores } from '.';
import { ModalProps } from 'react-bootstrap/esm/Modal';

export interface UIStoreType {
  isLoading: boolean;
  isMobile: boolean;
  innerHeight: number;
  innerWidth: number;

  isToasterShown: boolean;
  toasterOptions: ToasterOptions;

  updateWindowSizes: ({
    innerHeight,
    innerWidth,
  }: {
    innerWidth: number;
    innerHeight: number;
  }) => void;

  openToaster: (toasterOptions: ToasterOptions) => void;
  closeToaster: () => void;

  isAlertShown: boolean;
  alertOptions: {
    size?: ModalProps['size'];
    renderContent?: () => React.ReactNode;
    title?: string;
    text?: string;
    buttons?: {
      label: string;
      onClick?: () => void;
    }[];
  };
  openAlert: (alertOptions: UIStoreType['alertOptions']) => void;
  closeAlert: () => void;
  getAlertOptions: () => UIStoreType['alertOptions'];

  isDrawerOpen: boolean;
  closeDrawer: () => void;
  openDrawer: () => void;
}

class UIStore {
  @observable mainLayoutRef = null;
  @observable innerHeight = window.innerHeight;
  @observable innerWidth = window.innerWidth;
  @observable isMobile = false;

  @observable isToasterShown = false;
  @observable toasterOptions: ToasterOptions = {
    buttons: [],
    text: '',
    autoClose: true,
  };

  @observable isAlertShown: UIStoreType['isAlertShown'] = false;
  @observable alertOptions: UIStoreType['alertOptions'] = {};

  @observable isDrawerOpen: UIStoreType['isDrawerOpen'] = false;

  @action
  closeDrawer = () => {
    this.isDrawerOpen = false;
  };

  @action
  openDrawer = () => {
    this.isDrawerOpen = true;
  };

  @action
  updateWindowSizes({
    innerHeight,
    innerWidth,
  }: {
    innerHeight: number;
    innerWidth: number;
  }) {
    this.innerHeight = innerHeight || this.innerHeight;
    this.innerWidth = innerWidth || this.innerWidth;
    this.isMobile = innerWidth <= metrics.breakPoints.mobile;
  }

  @action
  openToaster = (toasterOptions: ToasterOptions) => {
    this.toasterOptions = toasterOptions;
    this.isToasterShown = true;
  };

  @action
  closeToaster = () => {
    this.isToasterShown = false;
  };

  @action
  updateMainLayoutRef = (ref: any) => {
    this.mainLayoutRef = ref;
  };

  @action
  openAlert: UIStoreType['openAlert'] = (alertOptions) => {
    this.alertOptions = alertOptions;
    this.isAlertShown = true;
  };

  @action
  closeAlert = () => {
    this.isAlertShown = false;
    this.alertOptions = {};
  };

  getAlertOptions: UIStoreType['getAlertOptions'] = () => {
    return toJS(this.alertOptions);
  };
}

export default new UIStore();

export function useUiStore(): UIStoreType {
  const { ui } = useStores();
  return ui as UIStoreType;
}
