import * as React from 'react';
import styled from 'styled-components';
import FittTextInput from '../common/FittTextInput';
import i18n from '../../constants/i18n';
import Button from '../common/Button';
import { margins } from '../../constants/metrics';
import { observer } from 'mobx-react';
import { useUserStore } from '../../stores/user';
import { useUiStore } from '../../stores/ui';
import { formatError } from '../../utils/text';
import { useRouterStore } from '../../stores/router';
import { GoogleAuthButton, FacebookAuthButton } from './SocialButtons';
import {
  TextNormal500,
  TextNormal600,
  TextSubtitle,
} from '../common/Typography';
import colors from '../../constants/colors';
import FittCheckBox from '../common/FittCheckBox';

const LoginView = observer(() => {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState('');
  const [mode, setMode] = React.useState<'signin' | 'signup'>('signin');
  const [
    termsAndConditionsAccepted,
    setTermsAndConditionsAccepted,
  ] = React.useState(false);
  const userStore = useUserStore();
  const uiStore = useUiStore();
  const router = useRouterStore();

  const login = async () => {
    try {
      if (mode === 'signin') {
        setLoading(true);
        await userStore.login({
          email,
          password,
        });
        router.push('/profile');
      } else if (!termsAndConditionsAccepted) {
        uiStore.openToaster({
          type: 'error',
          text: i18n(
            `LoginView.acceptTermsConditions`,
            `You must accept the terms and conditions.`,
          ),
        });
      } else if (!_checkIfPasswordIsValid()) {
        uiStore.openToaster({
          type: 'error',
          text: i18n(
            `LoginView.incorrectPassword`,
            `Password must have at least 6 characters and include at least one number.`,
          ),
        });
      } else if (password !== passwordConfirmation) {
        uiStore.openToaster({
          type: 'error',
          text: i18n(`LoginView.passwordMustMatch`, `Passwords must match`),
        });
      } else {
        setLoading(true);
        await userStore.signup({
          email,
          password,
        });
        router.push('/profile');
      }
    } catch (e) {
      uiStore.openToaster({
        type: 'error',
        text: formatError(e),
      });
    } finally {
      setLoading(false);
    }
  };

  const _checkIfPasswordIsValid = () => {
    const regex = /\d/;
    if (password?.length < 6 || !password.match(regex)) {
      return false;
    }
    return true;
  };

  return (
    <Main>
      <LoginAndSignup>
        <TextSubtitle
          style={{
            fontWeight: 600,
            color: mode === 'signin' ? colors.primary : colors.gray,
            cursor: 'pointer',
          }}
          onClick={() => setMode('signin')}
          css={`
            :hover {
              opacity: 0.6;
              transition-timing-function: ease-in;
              transition-timing-function: ease-out;
              transition: 0.25s;
            }
          `}
        >
          {i18n('LoginView.buttonsignin', 'Sign in')}
        </TextSubtitle>
        <TextSubtitle
          style={{
            fontWeight: 600,
            color: !(mode === 'signin') ? colors.primary : colors.gray,
            cursor: 'pointer',
          }}
          onClick={() => setMode('signup')}
          css={`
            :hover {
              opacity: 0.6;
              transition-timing-function: ease-in;
              transition-timing-function: ease-out;
              transition: 0.25s;
            }
          `}
        >
          {i18n('LoginView.buttonsignup', 'Sign up')}
        </TextSubtitle>
      </LoginAndSignup>

      <FittTextInput
        label={i18n('LoginView.emailtitle', 'Email')}
        style={{ marginBottom: margins[6], maxWidth: 320 }}
        value={email}
        onChangeText={setEmail}
        type="email"
        inputStyle={{ width: '100%' }}
      />
      <FittTextInput
        label={i18n('LoginView.passwordtitle', 'Password')}
        style={{ marginBottom: margins[6], maxWidth: 320 }}
        value={password}
        onChangeText={setPassword}
        type="password"
        inputStyle={{ width: '100%' }}
      />

      {mode === 'signup' ? (
        <FittTextInput
          label={i18n(
            'LoginView.passwordtitleConfirmation',
            'Password confirmation',
          )}
          style={{ marginBottom: margins[1], maxWidth: 320 }}
          value={passwordConfirmation}
          onChangeText={setPasswordConfirmation}
          type="password"
          inputStyle={{ width: '100%' }}
        />
      ) : null}

      {mode === 'signup' ? (
        <TermsAndConditions>
          <FittCheckBox
            onChange={(newValue) => setTermsAndConditionsAccepted(newValue)}
            value={termsAndConditionsAccepted}
            style={{ marginTop: 4, marginRight: 5 }}
          />

          <TextNormal500>
            {i18n(
              'LoginView.byContinuing',
              'By continuing with the sign up, you accept the',
            )}{' '}
            <a
              style={{
                cursor: 'pointer',
              }}
              href="https://fittcoach.ca/en/terms-of-service"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <TextNormal600 style={{ textDecoration: 'underline' }}>
                {' '}
                {i18n('LoginView.termsAndConditions', 'terms and conditions.')}
              </TextNormal600>
            </a>
          </TextNormal500>
        </TermsAndConditions>
      ) : null}

      <Button
        label={
          mode === 'signin'
            ? i18n('LoginView.buttonsignin', 'Sign in')
            : i18n('LoginView.buttonsignup', 'Sign up')
        }
        style={{
          marginTop: margins[8],
          marginBottom: margins[4],
          width: '100%',
        }}
        onClick={login}
        loading={loading}
      />

      <GoogleAuthButton
        style={{ marginBottom: margins[4], width: '100%' }}
        mode={mode}
      />
      <FacebookAuthButton
        style={{ marginBottom: margins[4], width: '100%' }}
        mode={mode}
      />
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoginAndSignup = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-bottom: ${(props) => props.theme.marginsCSS[8]};
`;

const TermsAndConditions = styled.div`
  display: flex;
`;

export default LoginView;
