import * as React from 'react';
import { useTrainerStore } from '../../stores/trainer';
import {
  TextTitle,
  TextSubtext,
  TextNormal600,
  TextNormal,
} from '../common/Typography';
import FittLoading from '../common/FittLoading';
import styled from 'styled-components';
import images from '../../constants/images';
import colors from '../../constants/colors';
import ReviewCell from './ReviewCell';
import { useRouterStore } from '../../stores/router';
import { useUiStore } from '../../stores/ui';
import { observer } from 'mobx-react';
import FittBox from '../common/FittBox';
import { margins, breakPoints } from '../../constants/metrics';
import TrainerCell from '../explore/TrainerCell';
import Button from '../common/Button';
import i18n, { getDeviceLangage } from '../../constants/i18n';
import goalsTranslations from '../../constants/translations/goals';
import { useUserStore } from '../../stores/user';
import { ScrollView } from 'react-native';
import WelcomePopup from '../explore/WelcomePopup';

function getTranslation(specialization: string) {
  let translation = '';
  const language = getDeviceLangage();

  const translationKey = Object.keys(goalsTranslations).find(
    (key) =>
      //@ts-ignore
      goalsTranslations[key].en === specialization ||
      //@ts-ignore
      goalsTranslations[key].fr === specialization,
  );

  try {
    //@ts-ignore
    translation = goalsTranslations[translationKey][language];
  } catch (error) {
    translation = specialization;
  }

  return translation;
}

const formatListOfStrings = ({ strings }: { strings: string[] }) => {
  const listLength = strings.length;

  return (
    <TextNormal600 style={{ color: colors.primary }}>
      {strings.map((string, index) => {
        if (listLength === 1) {
          return string;
        } else if (index < listLength - 1) {
          return (
            <TextNormal600 style={{ color: colors.primary }}>
              {string}
              <TextNormal600 style={{ color: colors.black }}>
                {', '}
              </TextNormal600>
            </TextNormal600>
          );
        } else {
          return string;
        }
      })}
    </TextNormal600>
  );
};

const TrainerDetailsScreen = observer(() => {
  const trainerStore = useTrainerStore();
  const router = useRouterStore();
  const [isFetchingInit, setIsFetchingInit] = React.useState(true);
  const trainerId = router.location.pathname.replace('/trainer/', '');
  const userStore = useUserStore();
  const uiStore = useUiStore();

  React.useEffect(() => {
    const firstFetch = async () => {
      setIsFetchingInit(true);
      await trainerStore.fetchTrainer({
        trainerId: trainerId,
      });
      setIsFetchingInit(false);
    };

    firstFetch();
  }, [trainerId, trainerStore]);

  const trainer = trainerStore.getCurrentTrainer()!;

  if (isFetchingInit || trainerStore.isFetchingTrainer) {
    return (
      <Main>
        <FittLoading
          style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}
        />
      </Main>
    );
  }

  return (
    <Main>
      <Column>
        <TrainerCell trainer={trainer} showDetails={true} disableHover={true} />
        <Button
          label={i18n('TrainerDetailsScreen.requestToBook', 'Request to book')}
          style={{ marginRight: margins[4], width: '100%', marginBottom: 20 }}
          onClick={() => {
            const me = userStore.getMe();
            if (me) {
              router.push(`/book?trainerId=${trainer.id}`);
            } else {
              router.push(`/auth`);
            }
          }}
        />
        <TextSubtext style={{ width: 330, marginBottom: 20 }}>
          {i18n(
            `TrainerDetailsScreen.cancellationPolicy`,
            `Any single session may be cancelled and
            fully refunded up to 24 hours prior to the
            booking date & time. Cancellations with
            less than 24 hours notice cannot be
            refunded.`,
          )}
        </TextSubtext>
        <Button
          label={i18n(
            'TrainerDetailsScreen.messageFittcoach',
            'Message Fittcoach',
          )}
          style={{
            marginRight: margins[4],
            width: '100%',
            marginBottom: 20,
            backgroundColor: colors.gray,
          }}
          onClick={() => {
            uiStore.openAlert({
              renderContent: () => <WelcomePopup />,
            });
          }}
        />
        <Button
          label={i18n(
            'TrainerDetailsScreen.bookpackageSessions',
            'Book package-sessions',
          )}
          style={{
            marginRight: margins[4],
            width: '100%',
            marginBottom: 20,
            backgroundColor: colors.gray,
          }}
          onClick={() => {
            uiStore.openAlert({
              renderContent: () => <WelcomePopup />,
            });
          }}
        />
        <Button
          label={i18n(
            'TrainerDetailsScreen.bookworkoutplan',
            'Book workout plan',
          )}
          style={{
            marginRight: margins[4],
            width: '100%',
            marginBottom: 20,
            backgroundColor: colors.gray,
          }}
          onClick={() => {
            uiStore.openAlert({
              renderContent: () => <WelcomePopup />,
            });
          }}
        />
      </Column>

      <Column>
        <FittBox
          style={{
            flexDirection: 'column',
            marginBottom: margins[4],
          }}
          css={`
            max-width: 425px;
            @media (max-width: ${breakPoints.mobile}px) {
              max-width: 100%;
            }
          `}
        >
          <TextTitle style={{ marginBottom: margins[3] }}>
            {i18n('TrainerDetailsScreen.aboutMe', 'About me')}
          </TextTitle>
          <TextNormal>
            {trainer.bioFr ? trainer.bioFr : trainer.bio}
            {trainer.bioFr ? (
              <>
                <br />
                <br />
                {trainer.bioEn}
              </>
            ) : (
              trainer.bioEn
            )}
          </TextNormal>
        </FittBox>

        <FittBox
          style={{
            flexDirection: 'column',
            marginBottom: margins[4],
          }}
          css={`
            max-width: 425px;
            @media (max-width: ${breakPoints.mobile}px) {
              max-width: 100%;
            }
          `}
        >
          <TextTitle style={{ marginBottom: margins[2] }}>
            {i18n('TrainerDetailsScreen.specializations', 'Specializations')}
          </TextTitle>
          {formatListOfStrings({
            strings: trainer.certifications,
          })}
        </FittBox>

        <FittBox
          style={{
            flexDirection: 'column',
          }}
          css={`
            max-width: 425px;
            @media (max-width: ${breakPoints.mobile}px) {
              max-width: 100%;
            }
          `}
        >
          <TextTitle style={{ marginBottom: margins[2] }}>
            {i18n('TrainerDetailsScreen.services', 'Services')}
          </TextTitle>
          {formatListOfStrings({
            strings: trainer.trainingSpecialties.map((specialization) =>
              getTranslation(specialization),
            ),
          })}
        </FittBox>
      </Column>

      {trainer.reviews.length ? (
        <Column>
          <FittBox
            style={{
              flexDirection: 'column',
            }}
            css={`
              max-width: 550px;
              @media (max-width: ${breakPoints.mobile}px) {
                max-width: 100%;
              }
            `}
          >
            <ReviewSectionHeader>
              <TextTitle style={{ marginRight: 6 }}>
                {i18n('TrainerDetailsScreen.reviews', 'Reviews')}
              </TextTitle>
              <StarImg src={images.icons.star} alt="star" />
              <TextNormal600
                style={{
                  color: colors.primary,
                  fontSize: 18,
                  marginTop: 3,
                  marginRight: margins[1],
                }}
              >
                {trainer!.avgRating}
              </TextNormal600>
              <TextSubtext
                style={{ color: colors.gray, fontSize: 18, marginTop: 3 }}
              >
                {' (' + trainer.reviews.length + ')'}
              </TextSubtext>
            </ReviewSectionHeader>

            <ReviewListing>
              <ScrollView scrollEnabled style={{ height: 600 }}>
                {trainer.reviews.map((review) => (
                  <ReviewCell
                    key={review.id}
                    review={review}
                    css={`
                      width: 440px;
                      @media (max-width: ${breakPoints.mobile}px) {
                        width: 100%;
                      }
                    `}
                  />
                ))}
              </ScrollView>
            </ReviewListing>
          </FittBox>
        </Column>
      ) : null}
    </Main>
  );
});

const Main = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding-top: ${(props) => props.theme.safeTopDistance}px;
  padding-bottom: ${(props) => props.theme.safeTopDistance}px;
  background-color: ${(props) => props.theme.colors.background};
  @media (max-width: ${(props) => props.theme.breakPoints.small}px) {
    flex-wrap: wrap;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.theme.marginsCSS[4]};
  @media (max-width: ${(props) => props.theme.breakPoints.small}px) {
    margin-bottom: ${(props) => props.theme.safeTopDistance}px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    margin-right: ${(props) => props.theme.marginsCSS[0]};
  }
`;

const ReviewSectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${(props) => props.theme.paddingsCSS[1]};
`;

const StarImg = styled.img`
  margin-right: ${(props) => props.theme.paddingsCSS[1]};
  height: 20.24px;
  width: 20.24px;
`;

const ReviewListing = styled.div`
  display: flex;
  flex-direction: column;
`;

export default TrainerDetailsScreen;
