import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { useClipboard } from 'use-clipboard-copy';

import colors from '../../constants/colors';
import { TextNormal, TextNormal600 } from './Typography';
import { UIStoreType } from '../../stores/ui';
import { margins } from '../../constants/metrics';
import ToolTip from './ToolTip';
import Button from './Button';

const Input = styled.input`
  height: 36px;
  font-size: 18px;
  background-color: transparent;
  caret-color: ${colors.secondary};
  border: 0px solid ${colors.black};
  border-bottom-width: 1px;
  width: 320px;
  color: ${colors.secondary};
  font-family: ${(props) => props.theme.fonts.futura500};
  ::placeholder {
    color: ${colors.lightGray};
  }
  &:focus {
    outline: none;
    border-bottom-color: ${colors.primary};
  }
  ${({ inputStyle }: any) => inputStyle};
`;

interface P {
  style?: any;
  ui?: UIStoreType;
  label?: string;
  error?: string;
  onChange?: any;
  value?: any;
  type?: string;
  placeholder?: string;
  onChangeText?: (str: string) => void;
  maxLength?: string;
  min?: number;
  max?: number;
  inputStyle?: any;
  lightLabel?: string;
  disabled?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  getRef?: (ref: any) => void;
  getTextInputRef?: (ref: any) => void;
  loading?: boolean;
  toolTip?: string;
  clipboard?: boolean;
}

interface S {
  isFocused: boolean;
  clipboardRef: any;
}

@inject('ui')
@observer
class FittTextInput extends React.Component<P, S> {
  state = {
    isFocused: false,
    clipboardRef: undefined,
  };

  _onFocus = () => {
    this.setState({ isFocused: true }, () => {
      if (this.props.onFocus) {
        this.props.onFocus();
      }
    });
  };

  _onBlur = () => {
    this.setState({ isFocused: false }, () => {
      if (this.props.onBlur) {
        this.props.onBlur();
      }
    });
  };

  render() {
    let inputStyle = {
      // width: isMobile ? 180 : undefined,
      // minWidth: isMobile ? undefined : 300,
    };

    if (this.props.inputStyle) {
      inputStyle = {
        ...inputStyle,
        ...this.props.inputStyle,
      };
    }

    return (
      <View
        style={[styles.main, this.props.style]}
        ref={(ref: any) => {
          if (this.props.getRef) {
            this.props.getRef(ref);
          }
        }}
      >
        {this.props.label && (
          <div style={{ marginBottom: margins[0] }}>
            <TextNormal600 style={{ marginRight: margins[3] }}>
              {this.props.label}
            </TextNormal600>
            {this.props.toolTip && (
              <ToolTip style={{ marginTop: 5 }} text={this.props.toolTip} />
            )}
          </div>
        )}
        <View style={styles.row}>
          <Input
            ref={
              this.state.clipboardRef
                ? this.state.clipboardRef
                : (ref: any) => {
                    if (this.props.getTextInputRef) {
                      this.props.getTextInputRef(ref);
                    }
                  }
            }
            min={this.props.min}
            max={this.props.max}
            // maxLength={this.props.maxLength}
            onFocus={this._onFocus}
            onBlur={this._onBlur}
            disabled={this.props.disabled}
            value={this.props.value}
            onChange={(e: any) => {
              if (this.props.onChange) {
                this.props.onChange(e);
              }
              if (this.props.onChangeText) {
                if (
                  this.props.type === 'price' ||
                  this.props.type === 'number'
                ) {
                  const re = /^[0-9\b]+$/;
                  if (e.target.value === '' || re.test(e.target.value)) {
                    this.props.onChangeText(e.target.value);
                  }
                } else {
                  this.props.onChangeText(e.target.value);
                }
              }
            }}
            type={
              this.props.type === 'price'
                ? this.props.ui!.isMobile
                  ? 'tel'
                  : 'number'
                : this.props.type
            }
            placeholder={this.props.placeholder}
            // @ts-ignore
            inputStyle={[
              inputStyle,
              this.props.disabled
                ? { backgroundColor: 'rgb(230,230,230)' }
                : {},
            ]}
          />
          {this.props.clipboard ? (
            <CopyText
              setRef={(clipboardRef: any) => this.setState({ clipboardRef })}
            />
          ) : null}
        </View>

        {this.props.error && this.props.error.length ? (
          <TextNormal
            style={{
              color: colors.error,
              marginTop: 5,
            }}
          >
            {this.props.error}
          </TextNormal>
        ) : null}
        {this.props.loading ? (
          <View
            style={{
              width: 25,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'transparent',
              position: 'absolute',
              right: 15,
              top: 3,
              bottom: 3,
            }}
          >
            <ClipLoader
              size={16}
              color={colors.black}
              loading={this.props.loading}
            />
          </View>
        ) : null}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  main: {},
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default FittTextInput;

function CopyText({ setRef }: { setRef: any }) {
  const clipboard = useClipboard({
    copiedTimeout: 800,
  });

  React.useEffect(() => {
    setRef(clipboard.target);
  }, [clipboard.target, setRef]);
  return (
    <Button
      onClick={clipboard.copy}
      label={clipboard.copied ? 'Copié!' : 'Copier'}
      style={{ width: 70, marginLeft: 15, minWidth: 70 }}
    />
  );
}
