export default {
  'NavBar.downtheapp': { en: 'Download the App', fr: 'Télécharger ' },
  'NavBar.browsefittcoach': {
    en: 'Browse Fittcoaches',
    fr: 'Parcourir les Fittcoachs',
  },
  'NavBar.profile': { en: 'Profile', fr: 'Profil' },
  'NavBar.signin': { en: 'Sign in', fr: 'Se connecter' },
  'NavBar.mySessions': { en: 'My Sessions', fr: 'Mes séances' },
};

