import * as React from 'react';
import styled from 'styled-components';
import LoginView from './LoginView';
import FittBox from '../common/FittBox';

const AutScreen = () => {
  return (
    <Main>
      <FittBox
        style={{ flexDirection: 'column' }}
        css={`
          max-width: 360px;
          width: 100%;
          @media (max-width: ${(props: any) =>
              props.theme.breakPoints.mobile}px) {
          }
        `}
      >
        <LoginView />
      </FittBox>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${(props) => props.theme.safeTopDistance}px;
  padding-bottom: ${(props) => props.theme.safeTopDistance}px;
  @media (max-width: ${(props) => props.theme.breakPoints.mobile}px) {
    justify-content: flex-start;
    align-items: center;
  }
`;

export default AutScreen;
