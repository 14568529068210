import * as React from 'react';
import styled from 'styled-components';
import GoogleLogin from 'react-google-login';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { TextNormal500 } from '../common/Typography';
import i18n from '../../constants/i18n';
import images from '../../constants/images';
import config from '../../config';
import { useUiStore } from '../../stores/ui';
import { formatError } from '../../utils/text';
import { useUserStore } from '../../stores/user';
import { ClipLoader } from 'react-spinners';
import colors from '../../constants/colors';
import { useRouterStore } from '../../stores/router';

const GoogleAuthButton = ({
  mode = 'signin',
  style,
}: {
  mode?: 'signin' | 'signup';
  style?: React.CSSProperties;
}) => {
  const [loading, setLoading] = React.useState(false);
  const uiStore = useUiStore();
  const userStore = useUserStore();
  const router = useRouterStore();

  const onLoginWithGoogle = async (response: {
    accessToken: string;
    error: any;
  }) => {
    if (response.error) {
      if (
        response.error !== 'idpiframe_initialization_failed' &&
        response.error !== 'popup_closed_by_user'
      ) {
        uiStore.openToaster({
          type: 'error',
          text: i18n(
            'SocialButtons.onLoginWithGoogleError',
            'Error, please try again later.',
          ),
        });
      }
    } else {
      try {
        setLoading(true);
        await userStore.loginWithGoogle({ accessToken: response.accessToken });
        router.replace('/profile');
      } catch (e) {
        uiStore.openToaster({
          type: 'error',
          text: formatError(e),
        });
      } finally {
        setLoading(false);
      }
    }
  };

  let title =
    mode === 'signin'
      ? i18n('SocialButton.googleSignip', 'Sign in with Google')
      : i18n('SocialButton.googleSignup', 'Sign up with Google');

  return (
    <GoogleLogin
      clientId={config.googleClientId}
      render={(renderProps) => (
        <BaseButton
          title={title}
          style={style}
          img={images.icons.googleLogo}
          onClick={renderProps.onClick}
          loading={loading}
        />
      )}
      buttonText="Login"
      // @ts-ignore
      onSuccess={onLoginWithGoogle}
      // @ts-ignore
      onFailure={onLoginWithGoogle}
      cookiePolicy={'single_host_origin'}
    />
  );
};

const FacebookAuthButton = ({
  mode = 'signin',
  style,
}: {
  mode?: 'signin' | 'signup';
  style?: React.CSSProperties;
}) => {
  const [loading, setLoading] = React.useState(false);
  const uiStore = useUiStore();
  const userStore = useUserStore();
  const router = useRouterStore();
  let title =
    mode === 'signin'
      ? i18n('SocialButton.fbSignip', 'Sign in with Facebook')
      : i18n('SocialButton.fbSignup', 'Sign up with Facebook');

  const onLoginWithFacebook = async (response: {
    accessToken: string;
    error: any;
  }) => {
    if (response.accessToken) {
      try {
        setLoading(true);
        await userStore.loginWithFacebook({
          accessToken: response.accessToken,
        });
        router.replace('/profile');
      } catch (e) {
        uiStore.openToaster({
          type: 'error',
          text: formatError(e),
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <FacebookLogin
      appId={config.facebookAppId}
      fields="name,email,picture"
      callback={onLoginWithFacebook}
      // @ts-ignore
      render={(renderProps: any) => (
        <BaseButton
          title={title}
          style={style}
          img={images.icons.facebookLogo}
          onClick={renderProps.onClick}
          loading={loading}
        />
      )}
    />
  );
};

const BaseButton = ({
  title,
  img,
  style,
  onClick,
  loading,
}: {
  title: string;
  img: string;
  style?: React.CSSProperties;
  onClick?: any;
  loading?: boolean;
}) => {
  return (
    <BaseButtonContainer style={style} onClick={onClick}>
      {loading ? (
        <LoadingContainer>
          <ClipLoader color={colors.primary} size={24} />
        </LoadingContainer>
      ) : (
        <>
          <Logo src={img} alt="" />
          <TextNormal500>{title}</TextNormal500>
          <div style={{ width: 24 }} />
        </>
      )}
    </BaseButtonContainer>
  );
};

const BaseButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${(props) => props.theme.paddingsCSS[3]};
  padding-right: ${(props) => props.theme.paddingsCSS[3]};
  justify-content: space-between;
  width: 320;
  border: 1.5px solid ${(props) => props.theme.colors.black};
  border-radius: 10px;
  height: 46px;
  cursor: pointer;
  transition-timing-function: ease-in;
  transition: 0.2s;
  :hover {
    opacity: 0.6;
    transition-timing-function: ease-in;
    transition-timing-function: ease-out;
    transition: 0.25s;
  }
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export { GoogleAuthButton, FacebookAuthButton };
