export default {
  'HeroLanding.heroTextTitle4': {
    en: 'Affordable',
    fr: 'Des entraîneurs personnels',
  },
  'HeroLanding.heroTextTitle5': { en: 'personal trainers', fr: 'abordables' },
  'HeroLanding.heroTextTitle6': {
    en: 'at your fingertips.',
    fr: 'à portée de main.',
  },
  'HeroLanding.download': { en: 'Download the App', fr: 'Télécharger' },
  'HeroLanding.browse': {
    en: 'Browse Fittcoaches',
    fr: 'Parcourir les Fittcoachs',
  },
};
