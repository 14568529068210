import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { MobXProviderContext } from 'mobx-react';
import * as React from 'react';

import ui from './ui';
import user from './user';
import trainer from './trainer';
import gym from './gym';

export const routingStore = new RouterStore();
const browserHistory = createBrowserHistory();

export const history = syncHistoryWithStore(browserHistory, routingStore);

export default {
  ui,
  router: routingStore,
  user,
  trainer,
  gym,
};

export function useStores() {
  return React.useContext(MobXProviderContext);
}
